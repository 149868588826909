import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import DataBoard from '../views/dataBoard/index.vue'
import MonthlySales from '../views/monthlySales/index.vue'
import DdiDailys from '../views/ddiDailys/index.vue'
import AmountAppeals from '../views/amountAppeals/index.vue'
import Callback from '../views/Callback.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sys/auth/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/dataBoard',
    name: 'DataBoard',
    component: DataBoard,
  },
  {
    path: '/monthlySales',
    name: 'MonthlySales',
    component: MonthlySales,
  },
  {
    path: '/ddiDailys',
    name: 'DdiDailys',
    component: DdiDailys,
  },
  {
    path: '/amountAppeals',
    name: 'AmountAppeals',
    component: AmountAppeals,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.afterEach((to, from) => {
  document.title = 'AbbVie商业流向管理系统'
})

// 全局前置守卫
// 1. to :   你要去哪里    305  路由对象
// 2. from  : 你从 哪里来  301  路由对象
// 3. next 是否允许进入
//   next() 允许进入
//   next(false) 不允许进入
//   next('/login') 允许进入login
router.beforeEach((to, from, next) => {
  // /user /my-follow  /my-comments  /my-star
  const authPath = ['/user', '/my-follow', '/my-comments', '/my-star', '/edit']

  if (authPath.includes(to.path)) {
    // 未完待续

    let token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  } else {
    // 放行
    next()
  }
})

export default router
