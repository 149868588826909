<template>
  <div>
    <!-- <abbvie-header>AbbVie商业流向管理系统</abbvie-header> -->
    <div class="main">
      <!-- ddi -->
      <div class="ddi">
        <div class="ddi-title">
          <div class="title-left">
            <div class="logo"><img src="/ddi_logo.png" />DDI</div>
            <div class="date">
              <span>数据截至：{{ moment(ddiDate.salesBusinessDate).format('YYYY/MM/DD') }}</span>
              <!-- ddi气泡提示框 -->
              <van-popover v-model="ddiPopover" trigger="click">
                <div class="tips">
                  <div>1.进度=实际 / 指标</div>
                  <div>2.时间进度=数据截止日期 / 对应月自然天数</div>
                  <div>3.IMM实际数量为未拆分的医院销量</div>
                </div>
                <template #reference>
                  <van-icon name="question" size="16px" />
                </template>
              </van-popover>
              <!-- <van-icon name="question" size="16px" /> -->
            </div>
          </div>
          <div class="detail">
            <van-button class="btn-active" :disabled="loading" @click="showQty = !showQty"
              >{{ showQty ? '数量' : '金额' }}<van-icon class="sort-icon" name="sort"
            /></van-button>
            <van-button class="btn-active ml10" :disabled="loading" @click="isTarget = !isTarget"
              >{{ isTarget ? '目标' : '全量' }}<van-icon class="sort-icon" name="sort"
            /></van-button>
          </div>
        </div>
        <div class="ddi-content" @click="openRouter('/ddiDailys')" v-if="!showQty">
          <div class="info">
            <div class="title">实际金额</div>
            <div class="content">{{ ddiInfo.amountSum | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">指标金额</div>
            <div class="content">{{ ddiInfo.targetAmount | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">去年同期金额</div>
            <div class="content">{{ ddiLastYearInfo.amountSum | NumberFormat(true) }}</div>
          </div>
        </div>
        <div class="ddi-content" @click="openRouter('/ddiDailys')" v-else>
          <div class="info">
            <div class="title">实际数量</div>
            <div class="content">{{ ddiInfo.actualVendorQty | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">指标数量</div>
            <div class="content">{{ ddiInfo.targetVendorQty | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">去年同期数量</div>
            <div class="content">{{ ddiLastYearInfo.actualVendorQty | NumberFormat(true) }}</div>
          </div>
        </div>
        <div class="ddi-schedule" @click="openRouter('/ddiDailys')">
          <div class="schedule" v-if="!showQty">
            <div class="schedule-title">进度</div>
            <van-circle
              v-model="ddiInfo.yieldRateByAmount"
              :stroke-width="60"
              color="#25B6EE"
              layer-color="#F2F2F2"
              size="75px"
              :rate="ddiInfo.yieldRateByAmount"
              :speed="100"
              :text="scheduleText"
            >
              <template slot="default">
                <div class="schedule-text">{{ scheduleText }}</div>
              </template>
            </van-circle>
          </div>
          <div class="schedule" v-else>
            <div class="schedule-title">进度</div>
            <van-circle
              v-model="ddiInfo.yieldRate"
              :stroke-width="60"
              color="#25B6EE"
              layer-color="#F2F2F2"
              size="75px"
              :rate="ddiInfo.yieldRate"
              :speed="100"
              :text="scheduleText"
            >
              <template slot="default">
                <div class="schedule-text">{{ scheduleText }}</div>
              </template>
            </van-circle>
          </div>
          <div class="schedule">
            <div class="schedule-title">时间进度</div>
            <van-circle
              v-model="ddiInfo.dateSchedule"
              :stroke-width="60"
              color="#25B6EE"
              layer-color="#F2F2F2"
              size="75px"
              :rate="ddiInfo.dateSchedule"
              :speed="100"
              :text="dateScheduleText"
            >
              <template slot="default">
                <div class="schedule-text">{{ dateScheduleText }}</div>
              </template>
            </van-circle>
          </div>
        </div>
      </div>
      <!-- 月度 -->
      <div class="kpi">
        <div class="kpi-title">
          <div class="title-left">
            <div class="logo"><img src="/kpi_logo.png" />月度</div>
            <div class="date">
              <span>数据截至：{{ moment(kpiDate.salesBusinessDate).format('YYYY/MM') }}</span>
              <!-- 月度气泡提示框 -->
              <van-popover v-model="kpiPopover" trigger="click">
                <div class="tips">
                  <div>1.达成率=实际 / 指标</div>
                  <div>2.YoY=（当年该月实际-上年同期实际）/上年同期实际</div>
                </div>
                <template #reference>
                  <van-icon name="question" size="16px" />
                </template>
              </van-popover>
            </div>
          </div>
          <!-- <div class="detail" @click="openRouter('/monthlySales')">查看详情<van-icon name="arrow" /></div> -->
        </div>
        <div class="kpi-content" @click="openRouter('/monthlySales')" v-if="!showQty">
          <div class="info">
            <div class="title">实际金额</div>
            <div class="content">{{ kpiInfo.amountSum | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">指标金额</div>
            <div class="content">{{ kpiInfo.targetAmount | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">去年同期金额</div>
            <div class="content">{{ kpiLastYearInfo.amountSum | NumberFormat(true) }}</div>
          </div>
        </div>
        <div class="kpi-content" @click="openRouter('/monthlySales')" v-else>
          <div class="info">
            <div class="title">实际数量</div>
            <div class="content">{{ kpiInfo.actualVendorQty | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">指标数量</div>
            <div class="content">{{ kpiInfo.targetVendorQty | NumberFormat(true) }}</div>
          </div>
          <div class="info">
            <div class="title">去年同期数量</div>
            <div class="content">{{ kpiLastYearInfo.actualVendorQty | NumberFormat(true) }}</div>
          </div>
        </div>
        <div class="kpi-schedule" @click="openRouter('/monthlySales')">
          <div class="schedule" v-if="!showQty">
            <div class="schedule-title">达成率</div>
            <van-circle
              v-model="kpiInfo.yieldRateByAmount"
              :stroke-width="60"
              color="#1AB394"
              layer-color="#F2F2F2"
              size="75px"
              :rate="kpiInfo.yieldRateByAmount"
              :speed="100"
              :text="reachRateText"
            >
              <template slot="default">
                <div class="schedule-text">{{ reachRateText }}</div>
              </template>
            </van-circle>
          </div>
          <div class="schedule" v-else>
            <div class="schedule-title">达成率</div>
            <van-circle
              v-model="kpiInfo.yieldRate"
              :stroke-width="60"
              color="#1AB394"
              layer-color="#F2F2F2"
              size="75px"
              :rate="kpiInfo.yieldRate"
              :speed="100"
              :text="reachRateText"
            >
              <template slot="default">
                <div class="schedule-text">{{ reachRateText }}</div>
              </template>
            </van-circle>
          </div>
          <div class="schedule">
            <div class="schedule-title">YoY</div>
            <van-circle
              v-model="kpiLastYearInfo.yoy"
              :stroke-width="60"
              color="#1AB394"
              layer-color="#F2F2F2"
              size="75px"
              :rate="kpiLastYearInfo.yoy"
              :speed="100"
              :text="yoyText"
            >
              <template slot="default">
                <div class="schedule-text">{{ yoyText }}</div>
              </template>
            </van-circle>
          </div>
        </div>
      </div>
      <van-collapse v-model="activeNames" :border="false">
        <!-- 流向申诉 -->
        <div class="appeal" v-has="'mobile:flow:flow_appeal'">
          <van-collapse-item name="1" :border="false">
            <template #title>
              <div class="appeal-title">
                <div class="title-left">
                  <div class="logo"><img src="/appeal_logo.png" />流向申诉</div>
                  <div class="date">
                    <span>
                      <span v-if="appealDate.isWithinAppeal">申诉截止至：</span>{{ appealDate.appealDeadline }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <div class="appeal-content" @click="openRouter('/amountAppeals')">
              <div class="current">
                当期已生效/已提交<span>{{ appealStatus.effective }}/{{ appealStatus.totalCount }}</span>
              </div>
              <div class="all">
                <div>
                  核查中<span>{{ appealStatus.underVerification }}</span>
                </div>
                <div>
                  已拒绝<span>{{ appealStatus.rejected }}</span>
                </div>
                <div>
                  已确认<span>{{ appealStatus.confirmed }}</span>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </div>
        <!-- 运维日历 -->
        <div class="calendar">
          <van-collapse-item name="2" :border="false">
            <template #title>
              <div class="calendar-title">
                <div class="title-left">
                  <div class="logo"><img src="/calendar_logo.png" />运维日历</div>
                  <div class="date">
                    <span>{{ moment().format('YYYY/MM') }}</span>
                  </div>
                </div>
              </div>
            </template>
            <img :src="calendarUrl" v-if="calendarUrl" @click="previewImg" />
            <div class="non-img" v-else>暂无数据</div>
          </van-collapse-item>
        </div>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { getAction, postAction, downFile } from '@/api/manage'
import moment from 'moment'
export default {
  data() {
    return {
      loading: false, // 切换按钮是否禁用
      ddiPopover: false, // ddi气泡提示框
      kpiPopover: false, // 月度气泡提示框
      showQty: true, // 数量金额展示信息
      isTarget: true, // 目标全量展示信息
      activeNames: [],
      scheduleText: '',
      qtyText: '',
      amountText: '',
      dateScheduleText: '',
      reachRateQtyText: '',
      reachRateAmountText: '',
      reachRateText: '',
      qtyYoy: '',
      amountYoy: '',
      yoyText: '',
      ddiDate: {}, // ddi数据截至时间
      ddiInfo: {}, // ddi信息
      ddiLastYearInfo: {}, // ddi去年同期信息
      kpiDate: {}, // 月度数据截至时间
      kpiInfo: {}, // 月度信息
      kpiLastYearInfo: {}, // 月度去年同期信息
      appealDate: {}, // 流向申诉截至日期
      appealStatus: {}, // 流向申诉状态信息
      isAppealStatus: true, // 是否有查看申诉权限
      calendarUrl: '', // 运维日历
      territoryType: null, // 用户权限
      moment,
    }
  },
  mounted() {
    this.ddiRefreshDate()
    this.kpiRefreshDate()
    this.getAppealDate()
    this.getMaintenanceCalendar()
    this.territoryType = Vue.ls.get('territoryType')
  },
  watch: {
    showQty(val) {
      if (val) {
        this.scheduleText = this.qtyText
        this.reachRateText = this.reachRateQtyText
        this.kpiLastYearInfo.yoy = this.qtyYoy
        this.yoyText = (((this.qtyYoy || 0) * 100) / 100).toFixed(0) + '%'
      } else {
        this.scheduleText = this.amountText
        this.reachRateText = this.reachRateAmountText
        this.kpiLastYearInfo.yoy = this.amountYoy
        this.yoyText = (((this.amountYoy || 0) * 100) / 100).toFixed(0) + '%'
      }
    },
    isTarget(val) {
      this.loading = true
      this.ddiRefreshDate()
      this.kpiRefreshDate()
    },
  },
  methods: {
    // 查看详情
    openRouter(path) {
      if (path === '/ddiDailys') {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'div',
          $url_path: '/dataBoard',
          $element_content: 'DDI卡片',
        })
      } else if (path === '/monthlySales') {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'div',
          $url_path: '/dataBoard',
          $element_content: '月度卡片',
        })
      } else {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'div',
          $url_path: '/dataBoard',
          $element_content: '申诉卡片',
        })
      }
      this.$router.push({ path })
    },
    // 获取ddi数据截至时间
    ddiRefreshDate() {
      getAction('/sip/bu/ddiDaily/refreshDateRealTimeRetrieval').then(res => {
        console.log('获取ddi数据截至时间------', res)
        this.ddiDate = res.result
        let ym = this.ddiDate.territoryRefreshDate
        let startTime = moment(ym).startOf('month').format('YYYY-MM-DD')
        let businessDate = this.ddiDate.salesBusinessDate
        let endTime = moment(businessDate).subtract(1, 'year').format('YYYY-MM-DD')
        let businessDays = +moment(businessDate).format('DD') // 截止日期的天数
        let monthDays = +moment(businessDate).daysInMonth() // 截止日期所在月份天数
        let dateRate = +((businessDays / monthDays) * 100).toFixed(0) // 时间进度
        // 获取去年ym
        let lastYm = moment(ym).subtract(1, 'years').format('YYYYMM')
        let lastStartTime = moment(lastYm).startOf('month').format('YYYY-MM-DD')
        // let lastEndTime = moment(lastYm).endOf('month').format('YYYY-MM-DD')
        this.getDdiInfo(ym, startTime, businessDate, dateRate)
        this.getDdiLastYear(ym, startTime, businessDate, lastYm, lastStartTime, endTime)
      })
    },
    // 获取ddi信息
    getDdiInfo(ym, startTime, endTime, dateRate) {
      postAction('/sip/bu/ddiDaily/amountSum', { ym, startTime, endTime, isTarget: this.isTarget ? 1 : null }).then(
        res => {
          console.log('获取ddi信息------', res)
          this.ddiInfo = res.result[0] || {}
          if (this.ddiInfo.yieldRate) {
            this.ddiInfo.yieldRate = +this.ddiInfo.yieldRate * 100
            this.ddiInfo.yieldRateByAmount = +this.ddiInfo.yieldRateByAmount * 100
          } else {
            this.ddiInfo.yieldRate = 0
            this.ddiInfo.yieldRateByAmount = 0
          }
          if (this.ddiInfo.targetVendorQty) {
            this.qtyText = (((this.ddiInfo.yieldRate || 0) * 100) / 100).toFixed(0) + '%'
          } else {
            this.qtyText = '-'
          }
          if (this.ddiInfo.targetAmount) {
            this.scheduleText = (((this.ddiInfo.yieldRate || 0) * 100) / 100).toFixed(0) + '%'
            this.amountText = (((this.ddiInfo.yieldRateByAmount || 0) * 100) / 100).toFixed(0) + '%'
          } else {
            this.scheduleText = '-'
            this.amountText = '-'
          }

          if (this.showQty) {
            this.scheduleText = this.qtyText
          } else {
            this.scheduleText = this.amountText
          }

          // 时间进度
          this.ddiInfo.dateSchedule = dateRate
          this.dateScheduleText = (((this.ddiInfo.dateSchedule || 0) * 100) / 100).toFixed(0) + '%'
        }
      )
    },
    // 获取ddi去年同期金额
    getDdiLastYear(ym, startTime, endTime, lastYearYm, lastYearStartTime, lastYearEndTime) {
      postAction('/sip/bu/ddiDaily/amountSumYoY', {
        ym,
        startTime,
        endTime,
        lastYearYm,
        lastYearStartTime,
        lastYearEndTime,
        isTarget: this.isTarget ? 1 : null,
      }).then(res => {
        console.log('获取去年ddi信息------', res)
        if (res.code == 200) {
          this.loading = false
        }
        this.ddiLastYearInfo = res.result[0] || {}
      })
    },
    // 获取月度数据截至时间
    kpiRefreshDate() {
      getAction('/sip/bu/monthlySales/refreshDate').then(res => {
        console.log('获取月度截至时间--------', res)
        this.kpiDate = res.result
        let ym = this.kpiDate.territoryRefreshDate
        let startTime = moment(ym).startOf('month').format('YYYY-MM-DD')
        let endTime = moment(ym).endOf('month').format('YYYY-MM-DD')
        this.getKpiInfo(ym, startTime, endTime)
      })
    },
    // 获取月度信息
    getKpiInfo(ym, startTime, endTime) {
      postAction('/sip/bu/monthlySales/amountSum', { ym, startTime, endTime, isTarget: this.isTarget ? 1 : null }).then(
        res => {
          console.log('获取月度信息------', res)
          if (res.code == 200) {
            this.kpiInfo = res.result[0] || {}
            if (this.kpiInfo.yieldRate) {
              this.kpiInfo.yieldRate = +this.kpiInfo.yieldRate * 100
              this.kpiInfo.yieldRateByAmount = +this.kpiInfo.yieldRateByAmount * 100
            } else {
              this.kpiInfo.yieldRate = 0
              this.kpiInfo.yieldRateByAmount = 0
            }
            if (this.kpiInfo.targetVendorQty) {
              this.reachRateQtyText = (((this.kpiInfo.yieldRate || 0) * 100) / 100).toFixed(0) + '%'
            } else {
              this.reachRateQtyText = '-'
            }
            if (this.kpiInfo.targetAmount) {
              this.reachRateText = (((this.kpiInfo.yieldRate || 0) * 100) / 100).toFixed(0) + '%'
              this.reachRateAmountText = (((this.kpiInfo.yieldRateByAmount || 0) * 100) / 100).toFixed(0) + '%'
            } else {
              this.reachRateText = '-'
              this.reachRateAmountText = '-'
            }
            if (this.showQty) {
              this.reachRateText = this.reachRateQtyText
            } else {
              this.reachRateText = this.reachRateAmountText
            }
            // 获取去年ym
            let lastYm = moment(ym).subtract(1, 'years').format('YYYYMM')
            // let lastStartTime = moment(lastYm).startOf('month').format('YYYY-MM-DD')
            // let lastEndTime = moment(lastYm).endOf('month').format('YYYY-MM-DD')
            this.getKpiLastYear(ym, lastYm, this.kpiInfo.amountSum, this.kpiInfo.actualVendorQty)
          }
        }
      )
    },
    // 获取月度去年同期金额
    getKpiLastYear(ym, lastYearYm, amountSum, actualVendorQty) {
      postAction('/sip/bu/monthlySales/amountSumYoY', { ym, lastYearYm, isTarget: this.isTarget ? 1 : null }).then(
        res => {
          console.log('获取去年月度信息------', res)
          this.kpiLastYearInfo = res.result[0] || {}
          // 计算yoy进度
          if (this.kpiLastYearInfo.actualVendorQty) {
            this.qtyYoy = +(
              ((actualVendorQty - this.kpiLastYearInfo.actualVendorQty) / this.kpiLastYearInfo.actualVendorQty) *
              100
            ).toFixed(0)
            this.yoyText = (((this.qtyYoy || 0) * 100) / 100).toFixed(0) + '%'
          } else {
            this.qtyYoy = 0
            this.yoyText = '-'
          }
          if (this.kpiLastYearInfo.amountSum) {
            this.amountYoy = +(
              ((amountSum - this.kpiLastYearInfo.amountSum) / this.kpiLastYearInfo.amountSum) *
              100
            ).toFixed(0)
            this.yoyText = (((this.qtyYoy || 0) * 100) / 100).toFixed(0) + '%'
          } else {
            this.amountYoy = 0
            this.yoyText = '-'
          }
          this.kpiLastYearInfo.yoy = this.qtyYoy
          if (this.showQty) {
            this.kpiLastYearInfo.yoy = this.qtyYoy
            this.yoyText = (((this.qtyYoy || 0) * 100) / 100).toFixed(0) + '%'
          } else {
            this.kpiLastYearInfo.yoy = this.amountYoy
            this.yoyText = (((this.amountYoy || 0) * 100) / 100).toFixed(0) + '%'
          }
        }
      )
    },
    // 获取流向申诉截至日期
    getAppealDate() {
      getAction('/sip/appeal_time/getLatestAppealTime').then(res => {
        console.log('流向申诉截至日期--------', res)
        if (res.code == 200) {
          this.appealDate = res.result
          this.getAppealStatus(res.result.startTime, res.result.endTime)
        }
      })
    },
    // 获取流向申诉状态信息
    getAppealStatus(startTime, endTime) {
      postAction('/sip/appeal/status/countByWindow', { startTime, endTime }).then(res => {
        console.log('流向申诉状态信息--------', res)
        if (res.code == 200) {
          this.appealStatus = res.result
          this.isAppealStatus = true
        } else {
          this.isAppealStatus = false
        }
      })
    },
    // 获取运维日历
    getMaintenanceCalendar() {
      getAction('/sip/maintenanceCalendar/fileDownloadByYm', { ym: moment().format('YYYYMM') }).then(res => {
        console.log('获取运维日历--------', res)
        downFile('/system/file/download', { key: res.result }).then(r => {
          const blob = new Blob([r.data])
          const url = URL.createObjectURL(blob)
          this.calendarUrl = url
        })
      })
    },
    // 预览图片
    previewImg() {
      // 触发埋点
      this.$sensors.track('ButtonClicked', {
        $element_type: 'img',
        $url_path: '/dataBoard',
        $element_content: '运维日历图片',
      })
      this.$imagePreview([this.calendarUrl])
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-collapse-item__title {
  background: none;
  padding: 0;
}
/deep/ .van-collapse-item__content {
  background: none;
  padding: 0;
}
// 切换按钮样式
.van-button {
  height: 25px;
  border-radius: 8px;
  color: #fff;
  background-color: #c0c0c0;
  border: none;
  padding: 5px;
}
.btn-active {
  z-index: 999;
  background-color: #25b4f2;
}
.ml10 {
  margin-left: 10px;
}
.ml-10 {
  margin-left: -10px;
}
// 进度条文字样式
.schedule-text {
  width: 100%;
  height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
// 气泡提示框
.tips {
  color: #4e7092;
  font-weight: 600;
  padding: 10px;
  // div:nth-child(2) {
  // margin-bottom: 5px;
  // text-indent: 1em;
  // }
}
.main {
  height: 100%;
  padding: 16px;
}
.ddi,
.kpi {
  &-title {
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left {
      display: flex;
      align-items: center;
      .logo {
        font-size: 16px;
        font-weight: 600;
        margin-right: 12px;
        display: flex;
        align-items: center;
        img {
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
      .date {
        color: #7f7f7f;
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }
    }
    .detail {
      cursor: pointer;
    }
    .sort-icon {
      transform: rotate(90deg);
      margin-left: 3px;
    }
  }
  &-content {
    width: 100%;
    height: 81px;
    background: #fff;
    border-radius: 10px;
    padding: 18px 0 22px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      font-size: 12px;
      .content {
        font-size: 16px;
        font-weight: 600;
        color: #4e7092;
      }
    }
  }
  &-schedule {
    width: 100%;
    height: 127px;
    background: #fff;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .schedule {
      text-align: center;
      &-title {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
}
.appeal {
  &-title {
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left {
      display: flex;
      align-items: center;
      .logo {
        font-size: 16px;
        font-weight: 600;
        margin-right: 17px;
        display: flex;
        align-items: center;
        img {
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
      .date {
        color: #ff2323;
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  &-content {
    width: 100%;
    height: 86px;
    color: #4e7092;
    background: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    .current {
      width: 210px;
      height: 100%;
      font-size: 12px;
      border-right: 1px solid #ccc;
      line-height: 66px;
      display: flex;
      align-items: center;
      span {
        margin-left: 28px;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .all {
      width: calc(100% - 215px);
      height: 100%;
      font-size: 12px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      span {
        font-size: 16px;
        font-weight: 600;
        margin-left: 24px;
      }
    }
  }
}

.calendar {
  margin-top: 20px;
  &-title {
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left {
      display: flex;
      align-items: center;
      .logo {
        font-size: 16px;
        font-weight: 600;
        margin-right: 17px;
        display: flex;
        align-items: center;
        img {
          width: 17px;
          height: 17px;
          margin-right: 5px;
        }
      }
      .date {
        color: #7f7f7f;
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  .non-img {
    height: 50px;
    text-align: center;
    line-height: 50px;
  }
}
</style>
