<template>
  <div>
    <!-- <abbvie-header>AbbVie商业流向管理系统</abbvie-header> -->
    <div class="main">
      <div class="title">
        <div class="title-left">
          <div>月度</div>
          <div class="mr5">数据截至：{{ refreshDate | date('YYYY/MM') }}</div>
          <!-- 月度气泡提示框 -->
          <van-popover v-model="kpiPopover" trigger="click">
            <div class="tips">
              <div>1.达成率=实际 / 指标</div>
              <div>2.YoY=（当年该月实际-上年同期实际）/上年同期实际</div>
            </div>
            <template #reference>
              <van-icon name="question" size="16px" color="#7F7F7F" />
            </template>
          </van-popover>
        </div>
        <div class="detail">
          <van-button class="btn-active" :disabled="loading" @click="isTarget = !isTarget"
            >{{ isTarget ? '目标' : '全量' }}<van-icon class="sort-icon" name="sort"
          /></van-button>
        </div>
      </div>
      <!-- 下拉框 -->
      <van-dropdown-menu :close-on-click-outside="false" active-color="#3875c5">
        <van-dropdown-item :title="queryParam.ym | date('YYYY/MM')" ref="ym">
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            :max-date="maxYm"
            :formatter="formatter"
            @confirm="confirmDate"
            @cancel="cancelDate"
          />
        </van-dropdown-item>
        <van-dropdown-item v-model="queryParam.productIdList" ref="sku">
          <template #title>{{ skuShowList.length == 0 ? '选择SKU' : skuShowList.join(',') }}</template>
          <div class="sku-toobar">
            <div @click="cancelSku">重置</div>
            <div @click="confirmSku">确认</div>
          </div>
          <van-checkbox-group v-model="productIdCheckList" direction="horizontal" v-if="skuScreen.length > 0">
            <van-cell v-for="(item, index) in skuScreen" :key="index">
              <van-checkbox :name="item.id" shape="square" @click="changeSku(item)"
                >{{ item.specEnName }}
              </van-checkbox>
            </van-cell>
          </van-checkbox-group>
          <div class="loading" v-else>
            <van-loading type="spinner" color="#515151" size="24px" />
          </div>
        </van-dropdown-item>
        <!-- 更多筛选 -->
        <van-dropdown-item ref="filter" @open="openFilter" @close="closeFilter">
          <template #title>
            <img class="filter-active" src="/filter.png" v-show="isFilter" />
            <img class="filter-img" src="/filter_mtd.png" v-show="!isFilter" />
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
      <!-- 数据展示 -->
      <div class="data">
        <div class="data-info">
          <div class="data-title">拆分后数量(标准)</div>
          <div class="data-content">{{ monthlyData.actualQuantity | NumberFormat(true) }}</div>
        </div>
        <div class="data-info">
          <div class="data-title">指标数量(标准)</div>
          <div class="data-content">{{ monthlyData.targetQuantity | NumberFormat(true) }}</div>
        </div>
        <div class="data-info">
          <div class="data-title">拆分后金额</div>
          <div class="data-content">{{ monthlyData.actualAmount | NumberFormat(true) }}</div>
        </div>
        <div class="data-info">
          <div class="data-title">指标金额</div>
          <div class="data-content">{{ monthlyData.targetAmount | NumberFormat(true) }}</div>
        </div>
      </div>
      <!-- 进度展示 -->
      <div class="kpi-schedule">
        <div class="schedule">
          <div class="schedule-title">金额达成率</div>
          <van-circle
            v-model="yieldRate"
            :stroke-width="60"
            color="#1AB394"
            layer-color="#F2F2F2"
            size="75px"
            :rate="yieldRate"
            :speed="100"
            :text="yieldRateText"
          >
            <template slot="default">
              <div class="schedule-text">{{ yieldRateText }}</div>
            </template>
          </van-circle>
        </div>
        <div class="schedule">
          <div class="schedule-title">YoY(金额)</div>
          <van-circle
            v-model="yoy"
            :stroke-width="60"
            color="#1AB394"
            layer-color="#F2F2F2"
            size="75px"
            :rate="yoy"
            :speed="100"
            :text="yoyText"
          >
            <template slot="default">
              <div class="schedule-text">{{ yoyText }}</div>
            </template>
          </van-circle>
        </div>
      </div>
      <!-- 表格 -->
      <van-tabs v-model="tableActive">
        <div class="table-container">
          <van-tab title="按详情" name="detail">
            <DetailTable
              :tableData="detailData"
              :ipagination="ipagination"
              :total="detailTotal"
              @change="getDetailTable"
            ></DetailTable>
          </van-tab>
          <van-tab title="按SKU" name="sku">
            <SkuTable
              :tableData="skuData"
              :ipagination="ipagination"
              :total="skuTotal"
              @change="getSkuTable"
            ></SkuTable>
          </van-tab>
        </div>
      </van-tabs>
      <!-- 流向申诉悬浮按钮 -->
      <div class="appeal" @click="openDetail('/amountAppeals')" v-has="'mobile:flow:flow_appeal'" data-sensors-click>
        流向申诉
      </div>
    </div>
    <van-popup
      v-model="showFilter"
      round
      :overlay="false"
      position="bottom"
      get-container="#app"
      :style="{ height: '80%' }"
    >
      <div class="filter-title">筛选<img src="/filter_close.png" @click="close" /></div>
      <div class="filter-box">
        <!-- 筛选框 -->
        <van-form>
          <van-row>
            <van-col span="24" class="input-title">开始日期</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="queryParam.startTime"
                placeholder="请选择开始日期"
                @click="showStart = true"
              >
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">结束日期</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="queryParam.endTime"
                placeholder="请选择开始日期"
                @click="showEnd = true"
              >
              </van-field>
            </van-col>

            <van-col span="24" class="input-title">NSD</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="nsdName"
                placeholder="请选择NSD"
                @click="showNsd = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="queryParam.nsdTerritoryId" @click.stop="clearNsd" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>

            <van-col span="24" class="input-title">RM</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="rmName"
                placeholder="请选择RM"
                @click="showRm = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="queryParam.rmTerritoryId" @click.stop="clearRm" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>

            <van-col span="24" class="input-title">DM</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="dmName"
                placeholder="请选择DM"
                @click="showDm = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="queryParam.dmTerritoryId" @click.stop="clearDm" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>

            <van-col span="24" class="input-title">MR</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="mrName"
                placeholder="请选择MR"
                @click="showMr = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="queryParam.mrTerritoryId" @click.stop="clearMr" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
          </van-row>
          <div class="filter-btn">
            <van-button class="btn" round block @click="reset">重置</van-button>
            <van-button class="btn" round block type="info" @click="search">应用</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <van-popup v-model="showStart" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmStartDate"
        @cancel="showStart = false"
      />
    </van-popup>
    <van-popup v-model="showEnd" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmEndDate"
        @cancel="showEnd = false"
      />
    </van-popup>
    <!-- NSD -->
    <van-popup v-model="showNsd" position="bottom">
      <van-picker
        show-toolbar
        :columns="nsdList"
        value-key="userName"
        @confirm="confirmNsd"
        @cancel="showNsd = false"
      />
    </van-popup>
    <!-- RM -->
    <van-popup v-model="showRm" position="bottom">
      <van-picker show-toolbar :columns="rmList" value-key="userName" @confirm="confirmRm" @cancel="showRm = false" />
    </van-popup>
    <!-- NSD -->
    <van-popup v-model="showNsd" position="bottom">
      <van-picker
        show-toolbar
        :columns="nsdList"
        value-key="userName"
        @confirm="confirmNsd"
        @cancel="showNsd = false"
      />
    </van-popup>
    <!-- DM -->
    <van-popup v-model="showDm" position="bottom">
      <van-picker show-toolbar :columns="dmList" value-key="userName" @confirm="confirmDm" @cancel="showDm = false" />
    </van-popup>
    <!-- MR -->
    <van-popup v-model="showMr" position="bottom">
      <van-picker show-toolbar :columns="mrList" value-key="userName" @confirm="confirmMr" @cancel="showMr = false" />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { getAction, postAction } from '@/api/manage'
import _ from 'lodash'
import moment from 'moment'
import DetailTable from './modules/DetailTable.vue'
import SkuTable from './modules/SkuTable.vue'
export default {
  components: {
    DetailTable,
    SkuTable,
  },
  data() {
    return {
      yieldRate: 0, // 金额达成率
      yieldRateText: '', // 金额达成率文案
      yoy: null,
      yoyText: '',
      currentDate: new Date(),
      queryParam: {
        ym: null,
        startTime: null,
        endTime: null,
        productIdList: [],
        nsdTerritoryId: null,
        rmTerritoryId: null,
        dmTerritoryId: null,
        mrTerritoryId: null,
      },
      /* 分页参数 */
      ipagination: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
      },
      productIdCheckList: [], // 选择的sku
      detailTotal: 0, // 详情表格总条数
      skuTotal: 0, // sku表格总条数
      refreshDate: null, // 数据截至时间
      skuScreen: [], // sku下拉框
      mrScreen: [], // mr下拉框
      skuShowList: [], // sku展示下拉框
      skuCheckName: [], // sku选择下拉框
      tableActive: 'detail', // 表格tab默认展示
      monthlyData: {}, // ytd数据
      detailData: [],
      skuData: [],
      value: 0,
      nsdList: [], // nsd列表
      rmList: [], // rm列表
      dmList: [], // dm列表
      mrList: [], // mr列表
      nsdName: '', // nsd展示名称
      rmName: '', // rm展示名称
      dmName: '', // dm展示名称
      mrName: '', // mr展示名称
      loading: false, // 切换按钮是否禁用
      showFilter: false, // 是否展示筛选框
      kpiPopover: false, // 月度气泡提示框
      showStart: false,
      showEnd: false,
      showNsd: false, // NSD
      showRm: false, // RM
      showDm: false, // DM
      showMr: false, // MR
      territoryType: null, // 用户权限
      isTarget: true, // 目标全量展示信息
    }
  },
  computed: {
    maxYm() {
      return moment().subtract(1, 'months').toDate()
    },
    minDate() {
      return moment(this.queryParam.ym).startOf('month').toDate()
    },
    maxDate() {
      return moment(this.queryParam.ym).endOf('month').toDate()
    },
    isFilter() {
      // let str
      let boolean
      if (
        this.queryParam.productIdList.length == 0 &&
        !this.queryParam.nsdTerritoryId &&
        !this.queryParam.rmTerritoryId &&
        !this.queryParam.dmTerritoryId &&
        !this.queryParam.mrTerritoryId
      ) {
        // str = '/filter_mtd.png'
        boolean = false
      } else {
        // str = '/filter.png'
        boolean = true
      }
      // return str
      return boolean
    },
  },
  mounted() {
    this.getRefreshDate()
    this.territoryType = Vue.ls.get('territoryType')
  },
  watch: {
    'queryParam.ym': {
      handler: function (val) {
        this.skuShowList = []
        this.queryParam.productIdList = []
        this.queryParam.nsdTerritoryId = null
        this.queryParam.rmTerritoryId = null
        this.queryParam.dmTerritoryId = null
        this.queryParam.mrTerritoryId = null
        this.queryParam.startTime = moment(val).startOf('month').format('YYYY-MM-DD')
        this.queryParam.endTime = moment(val).endOf('month').format('YYYY-MM-DD')
        this.getSkuScreen()
        this.getNsdList()
        this.getRmList()
        this.getDmList()
        this.getMrList()
      },
      deep: true, //深度侦听
    },
    'queryParam.productIdList': {
      handler: function (val) {
        this.getMonthlyAmount()
        this.getDetailTable()
        this.getSkuTable()
      },
      deep: true, //深度侦听
    },
    'queryParam.nsdTerritoryId': {
      handler: async function (val) {
        this.queryParam.rmTerritoryId = undefined
        this.rmName = ''
        this.queryParam.dmTerritoryId = undefined
        this.dmName = ''
        this.queryParam.mrTerritoryId = undefined
        this.mrName = ''
        this.getRmList()
        this.getDmList()
        this.getMrList()
      },
      deep: true, //深度侦听
    },
    'queryParam.rmTerritoryId': {
      handler: async function (val) {
        this.queryParam.dmTerritoryId = undefined
        this.dmName = ''
        this.queryParam.mrTerritoryId = undefined
        this.mrName = ''
        this.getMrList()
        this.getDmList()
      },
      deep: true, //深度侦听
    },
    'queryParam.dmTerritoryId': {
      handler: async function (val) {
        this.queryParam.mrTerritoryId = undefined
        this.mrName = ''
        this.getMrList()
      },
      deep: true, //深度侦听
    },
    isTarget(val) {
      this.loading = true
      this.getRefreshDate()
    },
  },
  methods: {
    // 清空单条数据
    clearNsd() {
      this.queryParam.nsdTerritoryId = undefined
      this.nsdName = ''
    },
    clearRm() {
      this.queryParam.rmTerritoryId = undefined
      this.rmName = ''
    },
    clearDm() {
      this.queryParam.dmTerritoryId = undefined
      this.dmName = ''
    },
    clearMr() {
      this.queryParam.mrTerritoryId = undefined
      this.mrName = ''
    },
    //点击更多筛选
    openFilter() {
      this.showFilter = true
    },
    close() {
      this.$refs.filter.toggle()
    },
    closeFilter() {
      this.showFilter = false
    },
    // 查询
    search() {
      this.getMonthlyAmount()
      this.getDetailTable()
      this.getSkuTable()
      this.$refs.filter.toggle()
    },
    // 重置
    reset() {
      for (let k in this.queryParam) {
        if (k != 'ym' && k != 'productIdList') {
          this.queryParam[k] = null
        } else if (k == 'productIdList') {
          this.queryParam[k] = []
        }
      }
      this.skuShowList = []
      this.nsdName = ''
      this.rmName = ''
      this.dmName = ''
      this.mrName = ''
      this.getRefreshDate()
      this.$refs.filter.toggle()
    },
    // 获取nsd
    getNsdList() {
      getAction('/sip/bu/ddiDaily/nsdScreen', { ym: this.queryParam.ym, attachableType: 'r3m_sale' }).then(res => {
        console.log('获取nsd------', res)
        this.nsdList = res.result
      })
    },
    // 选择nsd
    confirmNsd(value) {
      this.queryParam.nsdTerritoryId = value.territoryId
      this.nsdName = value.userName
      this.showNsd = false
    },

    // 获取rm
    getRmList() {
      getAction('/sip/bu/ddiDaily/rmScreen', {
        ym: this.queryParam.ym,
        attachableType: 'r3m_sale',
        ...this.queryParam,
      }).then(res => {
        console.log('获取rm------', res)
        this.rmList = res.result
      })
    },
    // 选择rm
    confirmRm(value) {
      this.queryParam.rmTerritoryId = value.territoryId
      this.rmName = value.userName
      this.showRm = false
    },

    // 获取dm
    getDmList() {
      getAction('/sip/bu/ddiDaily/dmScreen', {
        ym: this.queryParam.ym,
        attachableType: 'r3m_sale',
        ...this.queryParam,
      }).then(res => {
        console.log('获取dm------', res)
        this.dmList = res.result
      })
    },
    // 选择dm
    confirmDm(value) {
      this.queryParam.dmTerritoryId = value.territoryId
      this.dmName = value.userName
      this.showDm = false
    },

    // 获取mr
    getMrList() {
      getAction('/sip/bu/ddiDaily/mrScreen', {
        ym: this.queryParam.ym,
        attachableType: 'r3m_sale',
        ...this.queryParam,
      }).then(res => {
        console.log('获取mr------', res)
        this.mrList = res.result
      })
    },

    // 选择mr
    confirmMr(value) {
      this.queryParam.mrTerritoryId = value.territoryId
      this.mrName = value.userName
      this.showMr = false
    },
    openDetail(path) {
      this.$router.push({ path })
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    // 选择日期
    confirmDate(val) {
      this.queryParam.ym = moment(val).format('YYYYMM')
      this.$refs.ym.toggle()
    },
    confirmStartDate(val) {
      this.queryParam.startTime = moment(val).format('YYYY-MM-DD')
      this.showStart = false
    },
    confirmEndDate(val) {
      this.queryParam.endTime = moment(val).format('YYYY-MM-DD')
      this.showEnd = false
    },
    // 取消日期
    cancelDate() {
      this.$refs.ym.toggle()
    },
    // sku重置
    cancelSku() {
      this.queryParam.productIdList = []
      this.productIdCheckList = []
      this.skuShowList = []
      this.skuCheckName = []
      this.$refs.sku.toggle()
    },
    // sku确认
    confirmSku() {
      this.queryParam.productIdList = JSON.parse(JSON.stringify(this.productIdCheckList))
      this.skuShowList = JSON.parse(JSON.stringify(this.skuCheckName))
      this.$refs.sku.toggle()
    },
    // 选择SKU
    changeSku(val) {
      console.log(val)
      let index = this.skuCheckName.indexOf(val.specEnName)
      if (index != -1) {
        this.skuCheckName.splice(index, 1)
      } else {
        this.skuCheckName.push(val.specEnName)
      }
      console.log('复选框-------', this.skuCheckName)
    },
    // 获取数据截至时间
    getRefreshDate() {
      getAction('/sip/bu/monthlySales/refreshDate').then(res => {
        console.log('获取数据截至时间------', res)
        this.refreshDate = res.result.salesBusinessDate
        this.queryParam.ym = moment(this.refreshDate).format('YYYYMM')
        this.queryParam.startTime = moment(this.queryParam.ym).startOf('month').format('YYYY-MM-DD')
        this.queryParam.endTime = moment(this.queryParam.ym).endOf('month').format('YYYY-MM-DD')
        this.getMonthlyAmount()
        this.getDetailTable()
        this.getSkuTable()
      })
    },
    // 获取sku下拉框
    getSkuScreen() {
      getAction('/sip/mobile/mtd/selectSKU', { ym: this.queryParam.ym }).then(res => {
        console.log('获取sku下拉框------', res)
        this.skuScreen = res.result
      })
    },

    // 获取数据信息
    getMonthlyAmount() {
      postAction('/sip/mobile/monthly/amountSum', { ...this.queryParam, isTarget: this.isTarget ? 1 : null }).then(
        res => {
          console.log('获取数据信息------', res)
          this.monthlyData = res.result || {}
          // 获取去年ym
          let lastYm = moment(this.queryParam.ym).subtract(1, 'years').format('YYYYMM')
          let lastStart = moment(this.queryParam.startTime).subtract(1, 'years').format('YYYY-MM-DD')
          let lastEnd = moment(this.queryParam.endTime).subtract(1, 'years').format('YYYY-MM-DD')
          this.getMonthlyLastYear(this.queryParam.ym, lastStart, lastEnd, lastYm, this.monthlyData.actualAmount)

          if (!this.monthlyData.targetAmount) {
            this.yieldRate = 0
            this.yieldRateText = '-'
          } else {
            this.yieldRate = Number(
              ((this.monthlyData.actualAmount / this.monthlyData.targetAmount || 0) * 100).toFixed(0)
            )
            this.yieldRateText =
              ((this.monthlyData.actualAmount / this.monthlyData.targetAmount || 0) * 100).toFixed(0) + '%'
          }
        }
      )
    },
    // 获取去年数据信息
    getMonthlyLastYear(ym, lastYearStartTime, lastYearEndTime, lastYearYm, actualAmount) {
      let params = {
        ym,
        lastYearStartTime,
        lastYearEndTime,
        lastYearYm,
        ...this.queryParam,
        isTarget: this.isTarget ? 1 : null,
      }
      postAction('/sip/mobile/monthly/amountSumYoY', params).then(res => {
        console.log('获取去年数据信息------', res)
        if (res.result) {
          // 计算yoy进度
          if (!res.result.actualAmount) {
            this.yoy = 0
            this.yoyText = '-'
          } else {
            this.yoy = +(((actualAmount - res.result.actualAmount) / res.result.actualAmount) * 100).toFixed(0)
            this.yoyText = (((this.yoy || 0) * 100) / 100).toFixed(0) + '%'
          }
        } else {
          this.yoy = 0
          this.yoyText = '-'
        }
      })
    },
    // 获取详情表格
    getDetailTable(pagination = 1) {
      this.ipagination.pageNo = pagination
      postAction(
        '/sip/mobile/monthly/detailList',
        { ...this.queryParam, isTarget: this.isTarget ? 1 : null },
        { params: this.ipagination }
      ).then(res => {
        console.log('按详情表格------', res)
        this.detailData = res.result.records
        this.detailTotal = res.result.total
      })
    },
    // 获取SKU表格
    getSkuTable(pagination = 1) {
      this.ipagination.pageNo = pagination
      postAction(
        '/sip/mobile/monthly/listBySKU',
        { ...this.queryParam, isTarget: this.isTarget ? 1 : null },
        { params: this.ipagination }
      ).then(res => {
        console.log('按SKU表格------', res)
        if (res.code == 200) {
          this.loading = false
        }
        this.skuData = res.result.records
        this.skuTotal = res.result.total
      })
    },
  },
}
</script>

<style lang="less" scoped>
.mr5 {
  margin-right: 5px;
}
.table-container {
  overflow-x: auto;
  width: 100%;
}
/deep/ .el-table th {
  background-color: #f2f2f2 !important; /* 修改背景颜色为蓝色 */
}
// 下拉框
/deep/ .van-dropdown-menu__bar {
  height: 38px;
  padding: 5px 0;
  .van-dropdown-menu__item {
    padding: 0 10px;
    border-right: 1px solid #797979;
    .van-dropdown-menu__title {
      font-size: 12px;
      color: #555;
    }
  }
  .van-dropdown-menu__item:nth-child(3) {
    flex: 0.3;
    border-right: unset;
    padding-left: 0;

    .van-ellipsis {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .filter-img {
      width: 15px;
      height: 15px;
    }
    .filter-active {
      width: 13px;
      height: 13px;
    }
  }
}
// sku弹框
.sku-toobar {
  width: 100%;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  div {
    width: 54px;
    font-size: 14px;
    color: #576b95;
    text-align: center;
  }
  div:first-child {
    color: #969799;
  }
}
.loading {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}
/deep/ .van-checkbox-group {
  margin-top: 55px;
}
// tab切换栏
/deep/ .van-tabs__wrap {
  height: 40px !important;
}

/deep/ .van-tab__text {
  font-size: 12px;
  font-weight: 600;
}
/deep/ .van-tabs__line {
  background-color: #02a7f0;
  width: 25%;
}
// 进度条文字样式
.schedule-text {
  width: 100%;
  height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  height: 100%;
  padding-top: 16px;
}
// 流向申诉悬浮按钮
.appeal {
  width: 45px;
  height: 45px;
  font-size: 12px;
  color: #fff;
  padding: 10px;
  background-image: url(/appeal_btn.png);
  background-size: cover;
  position: fixed;
  right: 15px;
  bottom: 100px;
  z-index: 9999;
  display: flex;
  align-items: center;
  text-align: center;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 10px;
  &-left {
    font-size: 12px;
    color: #7f7f7f;
    margin-right: 5px;
    display: flex;
    align-items: center;
    div:first-child {
      font-size: 16px;
      font-weight: 600;
      color: #4e7092;
      margin-right: 10px;
    }
  }
}
// 切换按钮样式
.van-button {
  height: 25px;
  border-radius: 8px;
  color: #fff;
  background-color: #c0c0c0;
  border: none;
  padding: 5px;
}
.btn-active {
  z-index: 999;
  background-color: #25b4f2;
}
.detail {
  cursor: pointer;
}
.sort-icon {
  transform: rotate(90deg);
  margin-left: 3px;
}
// 气泡提示框
.tips {
  color: #4e7092;
  font-weight: 600;
  padding: 10px;
  // div:nth-child(2) {
  // margin-bottom: 5px;
  // text-indent: 1em;
  // }
}
// 数据展示
.data {
  width: 100%;
  height: 124px;
  font-size: 12px;
  color: #4e7092;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  &-info {
    flex: 1;
    margin: 0 5px 5px 0;
    width: calc((100% - 10px) / 2);
    min-width: calc((100% - 10px) / 2); // 加入这两个后每个item的宽度就生效了
    max-width: calc((100% - 10px) / 2); // 加入这两个后每个item的宽度就生效了
    // text-align: center;
    padding-left: 50px;
  }
  &-info:nth-child(2n) {
    // 去除第2n个的margin-right
    margin-right: 0;
  }
  &-content {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
  }
}
// 进度展示
.kpi-schedule {
  width: 100%;
  height: 127px;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .schedule {
    text-align: center;
    &-title {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}
// 筛选框
.filter-box {
  margin-top: 65px;
  padding: 0 25px;
}
.filter-title {
  width: 100%;
  height: 50px;
  color: #555;
  background: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 17px;
  z-index: 9999;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 14px;
    height: 14px;
  }
}
.input-title {
  font-size: 12px;
  color: #4e7092;
  margin-bottom: 10px;
}
.filter-input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-icon {
  display: flex;
  align-items: center;
}
.filter-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  .btn {
    width: 140px;
    height: 43px;
    font-size: 13px;
    color: #4e7092;
    border-radius: 16px;
    background: #f2f2f2;
    line-height: 43px;
  }
  .van-button--info {
    background-color: #6fc3ff;
    color: #fff;
    border: none;
  }
}
</style>
