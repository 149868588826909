<template>
  <div>
    <div class="fixed">
      <!-- 筛选框 -->
      <div class="filter-date">
        <div class="filter-left">
          <div class="date" @click="showDate = true">
            {{ queryParam.ym | date('MM月 YYYY') }} <img src="/arrow_down.png" />
          </div>
          <div class="appeal">
            <span v-if="appealDate.isWithinAppeal">申诉截止至：</span>{{ appealDate.appealDeadlineDetail }}
          </div>
        </div>
        <div :class="{ filter: true, 'filter-non': !isFilter, 'filter-active': isFilter }" @click="showFilter = true">
          <img src="/filter.png" v-if="!isFilter" />
          <img src="/filter_active.png" v-else />
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="list">
      <!-- 卡片信息 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="monthlySalesList.length == 0 ? '暂无数据' : '没有更多了'"
          @load="onLoad"
        >
          <div class="info" v-for="(item, index) in monthlySalesList" :key="index">
            <!-- 标题 -->
            <div class="info-title">
              <div class="content">
                {{ item.productSpecEnName }}
              </div>
              <div class="date">
                {{ item.businessMonthAndDay }}
              </div>
            </div>
            <!-- 信息类型 -->
            <div class="info-type">
              <div class="num">{{ item.oriQty }}{{ item.unit }}</div>
            </div>
            <!-- 内容信息 -->
            <div class="info-content">
              <div class="sale">
                <van-row>
                  <van-col span="6" class="sale-name">上游</van-col>
                  <van-col span="18">
                    <van-popover v-model="item.salsePopover" placement="top" trigger="click">
                      <div class="tips">
                        <div>{{ item.saleName }}</div>
                      </div>
                      <template #reference>
                        <div class="sale-content">{{ item.saleName }}</div>
                      </template>
                    </van-popover>
                  </van-col>
                </van-row>
              </div>
              <div class="purchase">
                <van-row>
                  <van-col span="6" class="purchase-name">采购方</van-col>
                  <van-col span="18">
                    <van-popover v-model="item.purchasePopover" placement="top" trigger="click">
                      <div class="tips">
                        <div>{{ item.purchaseName }}</div>
                      </div>
                      <template #reference>
                        <div class="purchase-content">{{ item.purchaseName }}</div>
                      </template>
                    </van-popover>
                  </van-col>
                </van-row>
              </div>
            </div>
            <!-- 查看详情 -->
            <div class="info-bottom">
              <div>{{ item.mrUserName }}</div>
              <div
                class="detail"
                v-if="item.appealId && appealDate.isWithinAppeal"
                @click="viewDetail(item.appealId)"
                data-sensors-click
              >
                查看详情 >
              </div>
              <div
                class="appeal"
                v-else-if="!item.appealId && appealDate.isWithinAppeal && showAppeal"
                @click="openAppeal(item)"
              >
                去申诉 >
              </div>
              <div class="appeal-non" v-else-if="!appealDate.isWithinAppeal || !showAppeal">不在申诉期</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="showFilter" round position="bottom" get-container="#app" :style="{ height: '80%' }">
      <div class="filter-title">筛选<img src="/filter_close.png" @click="showFilter = false" /></div>
      <div class="filter-box">
        <!-- 筛选框 -->
        <van-form>
          <van-row>
            <van-col span="24" class="input-title">日期</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="businessDate"
                placeholder="请选择日期"
                @click="showTime = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="businessDate" @click.stop="clearSelect('businessDate')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">上游</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="salerShowName"
                placeholder="请选择上游"
                @click="showSale = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="saleCode" @click.stop="clearSelect('saleCode')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">采购方</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="purchaseName"
                placeholder="请选择采购方"
                @click="showPurchase = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="purchaseName" @click.stop="clearSelect('purchaseName')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">SKU</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="productSpecEnName"
                placeholder="请选择SKU"
                @click="showSku = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="productSpecEnName" @click.stop="clearSelect('sku')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
          </van-row>

          <div class="filter-btn">
            <van-button class="btn" round block @click="reset">重置</van-button>
            <van-button class="btn" round block type="info" @click="search">应用</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- YM选择框 -->
    <van-popup v-model="showDate" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        :max-date="maxYm"
        type="year-month"
        title="选择年月"
        @confirm="confirmDate"
        @cancel="showDate = false"
      />
    </van-popup>
    <!-- 日期选择框 -->
    <van-popup v-model="showTime" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        :min-date="minTime"
        :max-date="maxTime"
        @confirm="confirmTime"
        @cancel="showTime = false"
      />
    </van-popup>
    <!-- 上游 -->
    <van-popup v-model="showSale" position="bottom">
      <van-search v-model="saleValue" @input="searchSale" placeholder="搜索上游" />
      <van-picker
        show-toolbar
        :columns="saleList"
        value-key="saleName"
        @confirm="confirmSale"
        @cancel="showSale = false"
      />
    </van-popup>
    <!-- 采购方 -->
    <van-popup v-model="showPurchase" position="bottom">
      <van-search v-model="purchaseValue" @input="searchPurchase" placeholder="搜索采购方" />
      <van-picker
        show-toolbar
        :columns="purchaseList"
        value-key="purchaseName"
        @confirm="confirmPurchase"
        @cancel="showPurchase = false"
      />
    </van-popup>
    <!-- SKU -->
    <van-popup v-model="showSku" position="bottom">
      <van-search v-model="skuValue" @input="searchSku" placeholder="搜索采购方" />
      <van-picker
        show-toolbar
        :columns="skuList"
        value-key="productSpecEnName"
        @confirm="confirmSku"
        @cancel="showSku = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      showDate: false, // YM选择
      showTime: false, // 日期选择
      showFilter: false, // 显示筛选框
      showSale: false, // 显示上游
      showPurchase: false, // 显示采购方
      showSku: false, // 显示sku
      isFilter: false, // 是否进行过搜索
      showAppeal: false, // 展示去申诉还是不在申诉期
      currentDate: new Date(),
      appealDeadDate: '', // 申诉期截至时间
      pageNo: 1, // 分页
      queryParam: {
        ym: moment().subtract(1, 'months').format('YYYYMM'),
      },
      businessDate: null,
      saleCode: null,
      salerShowName: null,
      purchaseName: null,
      productSpecEnName: null,
      saleValue: null,
      purchaseValue: null,
      skuValue: null,
      appealDate: {}, // 申诉期时间
      monthlySalesList: [], // 申诉列表
      saleList: [], // 上游列表
      purchaseList: [], // 采购方列表
      skuList: [], // sku列表
    }
  },
  filters: {
    appealState(val) {
      let str = ''
      if (val == 0) {
        str = '已提交'
      } else if (val == 1) {
        str = '核查中'
      } else if (val == 2) {
        str = '已确认'
      } else if (val == 3) {
        str = '已拒绝'
      } else if (val == 4) {
        str = '已生效'
      }
      return str
    },
  },

  computed: {
    maxYm() {
      return moment().subtract(1, 'months').toDate()
    },
    stateColor() {
      return val => {
        let str = ''
        if (val == 0) {
          str = 'tag-submit'
        } else if (val == 1) {
          str = 'tag-check'
        } else if (val == 2) {
          str = 'tag-confirm'
        } else if (val == 3) {
          str = 'tag-refuse'
        } else if (val == 4) {
          str = 'tag-effect'
        }
        return str
      }
    },
    minTime() {
      return moment(this.queryParam.ym).startOf('month').toDate()
    },
    maxTime() {
      return moment(this.queryParam.ym).endOf('month').toDate()
    },
  },
  watch: {
    'queryParam.ym': {
      handler: function (val) {
        this.pageNo = 1
        // 清空列表数据
        this.finished = false
        // 重新加载数据
        this.loading = true
        this.monthlySalesList = []
        this.getMonthlySales()
        this.getSaleList()
        this.getPurchaseList()
        this.getSkuList()
        if (this.appealDate.startYm <= Number(val) && Number(val) <= this.appealDate.endYm) {
          this.showAppeal = true
        } else {
          this.showAppeal = false
        }
      },
      deep: true, //深度侦听
    },
  },
  mounted() {
    this.getLatestAppeal()
    this.getSaleList()
    this.getPurchaseList()
    this.getSkuList()
  },
  methods: {
    // 查看详情
    viewDetail(id) {
      this.$emit('viewDetail', id)
    },
    // 去申诉
    openAppeal(record) {
      // 触发埋点
      this.$sensors.track('ButtonClicked', {
        $element_type: 'div',
        $url_path: '/amountAppeals',
        $element_content: '去申诉',
      })
      this.$emit('editAppeal', 'appeal', record)
    },
    handleStatus(value, index) {
      this.curIndex = index
      this.queryParam.state = value
    },
    // 选择YM
    confirmDate(val) {
      this.queryParam.ym = moment(val).format('YYYYMM')
      // this.getBoardData()
      // this.getRefresh()
      this.showDate = false
    },

    // 搜索上游
    searchSale: _.debounce(function (val) {
      this.getSaleList(val)
    }, 500),
    // 搜索采购方
    searchPurchase: _.debounce(function (val) {
      this.getPurchaseList(val)
    }, 500),
    // 搜索SKU
    searchSku: _.debounce(function (val) {
      this.getSkuList(val)
    }, 500),
    // 选择日期
    confirmTime(val) {
      this.businessDate = moment(val).format('YYYY-MM-DD')
      this.showTime = false
    },
    // 选择上游
    confirmSale(val) {
      console.log('选择上游------', val)
      this.saleCode = val.saleCode
      this.salerShowName = val.saleName
      this.showSale = false
    },
    // 选择采购方
    confirmPurchase(val) {
      console.log('选择采购方------', val)
      this.purchaseName = val.purchaseName
      this.showPurchase = false
    },
    // 选择sku
    confirmSku(val) {
      this.productSpecEnName = val.productSpecEnName
      this.showSku = false
    },
    // 清空上游
    clearSelect(select) {
      if (select === 'businessDate') {
        this.businessDate = null
      } else if (select === 'saleCode') {
        this.saleCode = null
        this.salerShowName = null
      } else if (select === 'purchaseName') {
        this.purchaseName = null
      } else {
        this.productSpecEnName = null
      }
    },
    // 获取申诉期
    getLatestAppeal() {
      getAction('/sip/appeal_time/getLatestAppealTime').then(res => {
        console.log('获取申诉期------', res)
        this.appealDate = res.result
        if (
          this.appealDate.startYm <= Number(this.queryParam.ym) &&
          Number(this.queryParam.ym) <= this.appealDate.endYm
        ) {
          this.showAppeal = true
        } else {
          this.showAppeal = false
        }
      })
    },
    // 获取上游下拉框
    getSaleList(saleName) {
      getAction('/sip/final_monthly_sales/saleList', { ym: this.queryParam.ym, saleName }).then(res => {
        this.saleList = res.result
      })
    },
    // 获取采购方下拉框
    getPurchaseList(purchaseName) {
      getAction('/sip/final_monthly_sales/purchaseList', { ym: this.queryParam.ym, purchaseName }).then(res => {
        this.purchaseList = res.result
      })
    },
    // 获取sku下拉框
    getSkuList(productSpecEnName) {
      getAction('/sip/final_monthly_sales/productList', { ym: this.queryParam.ym, productSpecEnName }).then(res => {
        this.skuList = res.result
      })
    },
    // 获取申诉列表
    getMonthlySales() {
      let params = {
        ...this.queryParam,
        isMobile: true,
        businessDate: this.businessDate,
        saleCode: this.saleCode,
        purchaseName: this.purchaseName,
        productSpecEnName: this.productSpecEnName,
        pageNo: this.pageNo,
      }
      getAction('/sip/final_monthly_sales/list', params).then(res => {
        console.log('获取申诉列表------', res)
        this.monthlySalesList = [...this.monthlySalesList, ...res.result.records]
        this.loading = false
        this.pageNo++
        if (res.result.records.length == 0) {
          this.finished = true
        }
        this.refreshing = false
      })
    },
    // 查询
    search() {
      this.pageNo = 1
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.monthlySalesList = []
      this.getMonthlySales()
      this.showFilter = false
      if (this.businessDate || this.saleCode || this.purchaseName || this.productSpecEnName) {
        this.isFilter = true
      } else {
        this.isFilter = false
      }
      // Vue.ls.set('queryParam', this.queryParam)
      // Vue.ls.set('filterDate', this.filterDate)
    },
    // 重置
    reset() {
      // this.queryParam = {}
      this.businessDate = null
      this.saleCode = null
      this.salerShowName = null
      this.saleVal = null
      this.purchaseName = null
      this.productSpecEnName = null
      // Vue.ls.remove('queryParam')
      this.isFilter = false
      this.pageNo = 1
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.monthlySalesList = []
      this.getMonthlySales()
    },
    onLoad() {
      this.getMonthlySales()
      // setTimeout(() => {
      // 加载状态结束
      // this.loading = false
      // 数据全部加载完成
      // this.finished = true
      // }, 1000)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
.fixed {
  width: 100%;
  position: fixed;
  min-height: 50px;
  z-index: 999;
  background-color: #f6f7fb;
  padding-bottom: 20px;
}
// 筛选框
.filter-date {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 10px;
  .filter-left {
    .date {
      font-size: 20px;
      font-weight: 600;
      color: #555;
      display: flex;
      align-items: center;
      img {
        width: 10px;
        height: 7px;
        margin-left: 10px;
      }
    }
    .appeal {
      font-size: 12px;
      color: #7f7f7f;
      margin-top: 5px;
    }
  }
  .filter {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .filter-non {
    background: #fff;
  }
  .filter-active {
    background: #5cc0eb;
  }
}

// 列表
.list {
  // margin-top: 20px;
  padding: 0 15px;
  padding-top: 95px;
  // 卡片信息
  .info {
    width: 100%;
    min-height: 152px;
    font-size: 12px;
    color: #4e7092;
    background: #fff;
    border-radius: 8px;
    padding: 10px 3px;
    padding-bottom: 0;
    margin-bottom: 15px;
    // 标题
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 20px;
      .content {
        font-size: 15px;
        font-weight: 600;
      }
      .date {
        // width: 60px;
        // height: 24px;
        font-size: 14px;
        color: #4e7092;
        opacity: 0.5;
        border-radius: 6px;
        text-align: center;
        line-height: 24px;
      }
    }
    // 信息类型
    &-type {
      font-weight: 600;
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin-top: 5px;
    }
    // 内容信息
    &-content {
      width: 100%;
      margin-top: 5px;
      padding: 0 25px 12px 17px;
      border-bottom: 1px solid #f2f2f2;
      .sale {
        margin-bottom: 5px;
      }
      .sale-name,
      .purchase-name {
        font-weight: 600;
      }
      .sale-content,
      .purchase-content {
        width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // overflow: hidden;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
      }
    }
    // 查看详情
    &-bottom {
      height: 40px;
      font-weight: 600;
      color: #02a7f0;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail {
        color: #4e7092;
        height: 100%;
        line-height: 40px;
      }
      .appeal {
        width: 75px;
        height: 26px;
        color: #fff;
        background: #6fc3ff;
        border-radius: 37px;
        line-height: 26px;
        text-align: center;
      }
      .appeal-non {
        width: 82px;
        height: 26px;
        color: #aaa;
        background: #d7d7d7;
        border-radius: 41px;
        line-height: 26px;
        text-align: center;
      }
    }
  }
}
// 气泡提示框
.tips {
  padding: 10px;
}
// 筛选框
.filter-box {
  margin-top: 65px;
  padding: 0 25px;
}
.filter-title {
  width: 100%;
  height: 50px;
  color: #555;
  background: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 17px;
  z-index: 9999;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 14px;
    height: 14px;
  }
}
.input-title {
  font-size: 12px;
  color: #4e7092;
  margin-bottom: 10px;
}
.filter-input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-icon {
  display: flex;
  align-items: center;
}
.filter-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  .btn {
    width: 140px;
    height: 43px;
    font-size: 13px;
    color: #4e7092;
    border-radius: 16px;
    background: #f2f2f2;
    line-height: 43px;
  }
  .van-button--info {
    background-color: #6fc3ff;
    color: #fff;
    border: none;
  }
}
</style>
