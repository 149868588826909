<template>
  <div class="header">
    <div class="icon" @click="back" v-if="getUrl"><van-icon name="arrow-left" color="#fff" size="14px" /></div>
    <div class="title"><slot></slot></div>
  </div>
</template>

<script>
import { postAction, syncPostAction } from '@/api/manage'

export default {
  data() {
    return {}
  },
  computed: {
    getUrl() {
      let url = window.location.href
      if (url.indexOf('login') != -1) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #3875c5;
  position: relative;
  padding-left: 10px;
  .title {
    // width: 100%;
    font-family: 'PingFang TC';
    font-size: 14px;
    color: #fff;
    // text-align: center;
  }
  .icon {
    margin-right: 10px;
    // position: absolute;
    // left: 10px;
    // width: 60px;
    // text-align: center;
  }
}
</style>
