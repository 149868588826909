<template>
  <div class="footer">
    <div :class="myPath === '/my_action.png' ? '' : 'my'"><img :src="myPath" @click="go('/my')" />我的</div>
    <div class="order"><img src="/add.png" @click="go('/editOrder')" />新增订单</div>
    <div :class="homePath === '/home_action.png' ? '' : 'home'"><img :src="homePath" @click="go('/order')" />订单</div>
  </div>
</template>

<script>
export default {
  computed: {
    homePath() {
      let path
      let url = window.location.href
      if (url.indexOf('order') != -1) {
        path = '/home_action.png'
      } else {
        path = '/home.png'
      }
      return path
    },
    myPath() {
      let path
      let url = window.location.href
      if (url.indexOf('my') != -1) {
        path = '/my_action.png'
      } else {
        path = '/my.png'
      }
      return path
    },
  },
  watch: {},
  methods: {
    go(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  color: #32b8c7;
  font-weight: 500;
  background: #fff;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 10px;
    }
  }
  .order {
    margin-top: -20px;
    img {
      width: 43px;
      height: 43px;
    }
  }
}
.order {
  margin: 0 60px;
}
.home {
  color: #000;
}
.my {
  color: #000;
}
</style>
