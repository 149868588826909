<template>
  <div class="main">
    <!-- <abbvie-header>AbbVie商业流向管理系统</abbvie-header> -->
    <van-tabs v-model="active" @change="changeTab">
      <van-tab title="申诉状态">
        <AppealStatus ref="appealStatus" @viewDetail="viewDetail" @getAppealDate="getAppealDate"></AppealStatus>
      </van-tab>
      <van-tab title="流向查看" v-if="hasAuth('mobile:flow:flow_view')">
        <FlowView ref="flowView" @viewDetail="viewDetail" @editAppeal="editAppeal"></FlowView>
      </van-tab>
    </van-tabs>
    <!-- 流向申诉悬浮按钮 -->
    <div class="appeal" @click="rotateButton" v-has="'mobile:flow:flow_view'">
      <transition name="slide-fade">
        <div class="missing" v-show="isClicked">
          <div @click="monthlyAppeal('monthly', true)">整月缺失申诉</div>
          <div></div>
          <div @click="monthlyAppeal('nonMonthly', false)">非整月缺失申诉</div>
        </div>
      </transition>
      <img :class="{ clicked: isClicked }" src="/appeal_add.png" />
    </div>
    <!-- 详情 -->
    <van-popup v-model="showDetail" round position="bottom" get-container="#app" :style="{ height: '80%' }">
      <div class="detail-popup-title">
        <van-icon name="arrow-left" v-show="showTransidDetail" @click="showTransidDetail = !showTransidDetail" />
        {{ showTransidDetail ? '生效流向' : '申诉详情' }}
        <img src="/filter_close.png" @click="showDetail = false" />
      </div>
      <div class="detail-popup-box" v-show="showDetail">
        <!-- 申诉详情 -->
        <div v-if="!showTransidDetail">
          <!-- 申诉结果 -->
          <div class="detail-title">
            <div>审核结果</div>
            <div :class="[stateColor(detail.state), 'state']">{{ detail.state | appealState }}</div>
          </div>
          <div class="detail-content">
            <van-cell :border="false" title="申诉二级状态" :value="detail.secondState" />
            <van-cell :border="false" title="审核日期">
              <template #default>
                <div>{{ detail.approvalDate | date('YYYY/MM/DD HH:mm:ss') }}</div>
              </template>
            </van-cell>

            <van-cell title="审核备注" :border="false">
              <template #default>
                <van-popover v-model="detail.remarkPopover" placement="top" trigger="click">
                  <div class="remark-tips">
                    <div>{{ detail.remark }}</div>
                  </div>
                  <template #reference>
                    {{ detail.remark }}
                  </template>
                </van-popover>
              </template>
            </van-cell>

            <van-cell v-if="detail.state == 4" :border="false" title="生效数量" :value="detail.realQty" />
            <van-cell v-if="detail.state == 4" :border="false" title="生效流向">
              <template #default>
                <div class="flow-code" v-if="detail.effectiveTransids" @click="showTransids = !showTransids">
                  <span>{{ detail.effectiveTransids[0] }}</span> >
                </div>
              </template>
            </van-cell>
            <div class="detail-transids" v-show="showTransids">
              <div class="transid" v-for="item in detail.effectiveTransids" @click="handleTransid(item)">
                {{ item }}
              </div>
            </div>
          </div>
          <!-- 基本信息 -->
          <div class="detail-title">
            <div>基本信息</div>
          </div>
          <div class="detail-content">
            <van-cell title="申诉编号" :border="false">
              <template #default>
                <div class="appeal-num">
                  <van-popover v-model="appealDetail.numberPopover" placement="top" trigger="click">
                    <div class="tips">
                      <div>{{ detail.number }}</div>
                    </div>
                    <template #reference>
                      <div>{{ detail.number }}</div>
                    </template>
                  </van-popover>
                  <img src="/copy.png" @click="copyText(detail.number, $event)" />
                </div>
              </template>
            </van-cell>
            <van-cell title="申诉类型" :value="detail.type" :border="false" />
            <van-cell title="流向代码" :value="detail.transid" :border="false" />
            <van-cell title="实际月份" :border="false">
              <template #default>
                <div>{{ String(detail.ym) | date('YYYY/MM') }}</div>
              </template>
            </van-cell>
            <van-cell title="实际日期" :border="false">
              <template #default>
                <div>{{ detail.businessDate | date('YYYY/MM/DD') }}</div>
              </template>
            </van-cell>

            <van-cell title="上游" :border="false">
              <template #default>
                <van-popover v-model="detail.salerPopover" placement="top" trigger="click">
                  <div class="tips">
                    <div>{{ detail.salerName }}</div>
                  </div>
                  <template #reference>
                    {{ detail.salerName }}
                  </template>
                </van-popover>
              </template>
            </van-cell>

            <van-cell title="采购方" :border="false">
              <template #default>
                <van-popover v-model="detail.purchasePopover" placement="top" trigger="click">
                  <div class="tips">
                    <div>{{ detail.purchaseName }}</div>
                  </div>
                  <template #reference>
                    {{ detail.purchaseName }}
                  </template>
                </van-popover>
              </template>
            </van-cell>

            <van-cell title="产品" :value="detail.brandName" :border="false" />
            <van-cell title="SKU" :value="detail.productName" :border="false" />
            <van-cell title="原始数量" :value="detail.oriQty" :border="false" />
            <van-cell title="申诉数量" :value="detail.qty" :border="false" v-if="!detail.isMonthly" />
            <van-cell title="单位" :value="detail.unit" :border="false" />
            <van-cell title="申诉时间" :value="detail.createdAt" :border="false" />
            <van-cell title="申诉理由" :border="false">
              <template #default>
                <van-popover v-model="detail.reasonPopover" placement="top" trigger="click">
                  <div class="tips">
                    <div>{{ detail.reason }}</div>
                  </div>
                  <template #reference>
                    {{ detail.reason }}
                  </template>
                </van-popover>
              </template>
            </van-cell>
            <van-cell title="申诉人" :value="detail.userName" :border="false" />
            <van-cell title="申诉人区域" :value="detail.operatorArea" :border="false" />
          </div>
          <van-button class="re-appeal" v-if="detail.state == 3 && showReAppealBtn" @click="reAppeal"
            >重新申诉</van-button
          >
        </div>
        <!-- 生效流向 -->
        <div class="detail-popup-box" v-else>
          <!-- 流向信息 -->
          <div class="detail-title">
            <div>流向信息</div>
          </div>
          <div class="detail-content">
            <van-cell :border="false" title="流向代码" :value="transidDetail.transid" />
            <van-cell :border="false" title="实际日期">
              <template #default>
                <div>{{ transidDetail.businessDate | date('YYYY/MM/DD') }}</div>
              </template>
            </van-cell>
            <van-cell title="上游" :border="false">
              <template #default>
                <van-popover v-model="transidDetail.salePopover" placement="top" trigger="click">
                  <div class="tips">
                    <div>{{ transidDetail.saleName }}</div>
                  </div>
                  <template #reference>
                    {{ transidDetail.saleName }}
                  </template>
                </van-popover>
              </template>
            </van-cell>
            <van-cell title="采购方" :border="false">
              <template #default>
                <van-popover v-model="transidDetail.purchasePopover" placement="top" trigger="click">
                  <div class="tips">
                    <div>{{ transidDetail.purchaseName }}</div>
                  </div>
                  <template #reference>
                    {{ transidDetail.purchaseName }}
                  </template>
                </van-popover>
              </template>
            </van-cell>
            <van-cell
              :border="false"
              title="省份/城市"
              :value="transidDetail.provinceName + '/' + transidDetail.cityName"
            />
            <van-cell :border="false" title="品牌" :value="transidDetail.productLineName" />
            <van-cell :border="false" title="SKU" :value="transidDetail.productsName" />
            <van-cell :border="false" title="数量" :value="transidDetail.qty" />
            <van-cell :border="false" title="金额" :value="transidDetail.amount" />
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 流向申诉/整月缺失申诉/非整月缺失申诉 -->
    <van-popup
      v-model="showAppeal"
      get-container="#app"
      round
      position="bottom"
      :style="{ height: appealType === 'monthly' ? '70%' : '80%' }"
    >
      <div class="detail-popup-title">
        {{ appealType === 'appeal' ? '流向申诉' : appealType === 'monthly' ? '整月缺失申诉' : '非整月缺失申诉' }}
        <img src="/filter_close.png" @click="showAppeal = false" />
      </div>
      <div v-show="showAppeal">
        <!-- 流向申诉 -->
        <div class="detail-popup-box">
          <!-- 基本信息 -->
          <div class="detail-title">
            <div>基本信息</div>
          </div>
          <van-form>
            <div class="detail-content" v-if="appealType === 'appeal'">
              <van-cell title="流向代码" :value="appealDetail.transid" :border="false" />
              <van-cell title="实际日期" :border="false">
                <template #default>
                  <div>{{ appealDetail.businessDate | date('YYYY/MM/DD') }}</div>
                </template>
              </van-cell>
              <van-cell title="上游" :border="false">
                <template #default>
                  <van-popover v-model="appealDetail.salePopover" placement="top" trigger="click">
                    <div class="tips">
                      <div>{{ appealDetail.saleName || appealDetail.salerName }}</div>
                    </div>
                    <template #reference>
                      {{ appealDetail.saleName || appealDetail.salerName }}
                    </template>
                  </van-popover>
                </template>
              </van-cell>
              <van-cell title="采购方" :border="false">
                <template #default>
                  <van-popover v-model="appealDetail.purchaserPopover" placement="top" trigger="click">
                    <div class="tips">
                      <div>{{ appealDetail.purchaseName }}</div>
                    </div>
                    <template #reference>
                      {{ appealDetail.purchaseName }}
                    </template>
                  </van-popover>
                </template>
              </van-cell>
              <van-cell
                title="SKU"
                :value="appealDetail.productSpecEnName || appealDetail.productName"
                :border="false"
              />
              <van-cell title="单位" :value="appealDetail.unit" :border="false" />
              <van-cell title="原始数量" :value="appealDetail.oriQty" :border="false" />
              <van-cell title="实际数量" :value="appealDetail.totalQty || appealDetail.actualQty" :border="false" />
            </div>
            <!-- 整月缺失申诉 -->
            <div class="detail-content" v-else>
              <van-cell
                id="ym"
                title="实际年月 *"
                :title-class="ymErr ? 'font-err' : ''"
                :border="false"
                @click="showYm = true"
              >
                <template #default>
                  <div v-if="flowLackAppeal.ym">{{ flowLackAppeal.ym | date('YYYYMM') }} ></div>
                  <div v-else :class="ymErr ? 'font-err' : ''">请选择 ></div>
                </template>
              </van-cell>
              <van-cell title="实际日期" :border="false" v-if="!flowLackAppeal.isMonthly" @click="showDate = true">
                <template #default>
                  <div v-if="flowLackAppeal.businessDate">{{ flowLackAppeal.businessDate | date('YYYY/MM/DD') }} ></div>
                  <div v-else>请选择 ></div>
                </template>
              </van-cell>
              <van-cell
                id="saler"
                title="上游 *"
                :title-class="salerErr ? 'font-err' : ''"
                :border="false"
                @click="showSale = true"
              >
                <template #default>
                  <div :class="salerErr ? 'font-err' : ''">{{ saleName ? saleName : '请选择 >' }}</div>
                  <span v-if="saleName" class="ml3">></span>
                </template>
              </van-cell>
              <van-cell
                id="purchase"
                title="采购方 *"
                :title-class="purchaseErr ? 'font-err' : ''"
                :border="false"
                @click="showPurchase = true"
              >
                <template #default>
                  <div :class="purchaseErr ? 'font-err' : ''">{{ purchaseName ? purchaseName : '请选择 >' }}</div>
                  <span v-if="purchaseName" class="ml3">></span>
                </template>
              </van-cell>
              <van-cell
                id="sku"
                title="SKU *"
                :title-class="skuErr ? 'font-err' : ''"
                :border="false"
                @click="showSku = true"
              >
                <template #default>
                  <div v-if="productIdList.length > 0">{{ skuName.join(',') }}</div>
                  <span v-if="productIdList.length > 0" class="ml6">></span>
                  <div v-else :class="skuErr ? 'font-err' : ''">请选择 ></div>
                </template>
              </van-cell>
            </div>
            <!-- 申诉信息 -->
            <div class="detail-title">
              <div>申诉信息</div>
            </div>
            <div class="detail-content">
              <van-cell
                id="qty"
                title="申诉数量 *"
                :title-class="inputErr ? 'font-err' : 'appeal-title'"
                :border="false"
                v-if="!flowLackAppeal.isMonthly || appealType === 'appeal'"
              >
                <template #default>
                  <van-field
                    :class="inputErr ? 'input-err' : 'input'"
                    input-align="right"
                    name="appealQty"
                    type="digit"
                    v-model="appealQty"
                  />
                </template>
              </van-cell>
              <van-cell title="申诉理由" title-class="appeal-title" :border="false">
                <template #default>
                  <van-field class="textarea" name="reason" type="textarea" input-align="right" v-model="reason" />
                </template>
              </van-cell>
            </div>
            <div class="form-btn">
              <van-button class="btn" round block @click="cancelSubmit">取消</van-button>
              <van-button class="btn" round block type="info" @click="onSubmit">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
    <!-- 实际年月 -->
    <van-popup v-model="showYm" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        :max-date="maxYm"
        @confirm="confirmYm"
        @cancel="showYm = false"
      />
    </van-popup>
    <!-- 实际日期 -->
    <van-popup v-model="showDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
        @cancel="showDate = false"
      />
    </van-popup>
    <!-- 上游 -->
    <van-popup v-model="showSale" position="bottom">
      <van-search v-model="saleValue" @input="searchSale" placeholder="搜索上游" />
      <van-picker show-toolbar :columns="saleList" value-key="name" @confirm="confirmSale" @cancel="showSale = false" />
    </van-popup>
    <!-- 采购方 -->
    <van-popup v-model="showPurchase" position="bottom">
      <van-search v-model="purchaseValue" @input="searchPurchase" placeholder="搜索采购方" />
      <van-picker
        show-toolbar
        :columns="purchaseList"
        value-key="name"
        @confirm="confirmPurchase"
        @cancel="showPurchase = false"
      />
    </van-popup>
    <!-- SKU -->
    <van-popup v-model="showSku" position="bottom" :style="{ height: '50%' }">
      <div class="sku-toobar">
        <div @click="cancelSku">取消</div>
        <div @click="confirmSku">确认</div>
      </div>
      <el-tree :data="skuList" :props="props" :check-strictly="flowLackAppeal.isMonthly">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span v-if="data.productList">{{ data.brandName }}</span>
          <span v-else>
            <el-checkbox-group v-model="productIdCheckList" @change="checkSku(data)">
              <el-checkbox :label="data.productId" :key="data.productId">{{ data.productName }}</el-checkbox>
            </el-checkbox-group>
          </span>
        </span>
      </el-tree>
    </van-popup>
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import _ from 'lodash'
import moment from 'moment'
import handleCopy from '@/utils/clipboard'
import AppealStatus from './modules/AppealStatus.vue'
import FlowView from './modules/FlowView.vue'

export default {
  components: {
    AppealStatus,
    FlowView,
  },
  data() {
    return {
      active: 0,
      currentDate: new Date(),
      isClicked: false,
      isAppeal: false, // 控制是否可发起申诉
      showDetail: false, // 展示详情弹框
      showAppeal: false, // 展示申诉弹框
      showYm: false, // 展示实际年月
      showDate: false, // 展示实际日期
      showTransids: false, // 是否显示生效流向集合
      showTransidDetail: false, // 是否显示生效流向详情
      inputErr: false, // 校验申诉理由
      ymErr: false, // 校验实际年月
      salerErr: false, // 校验上游
      purchaseErr: false, // 校验采购方
      skuErr: false, // 校验sku
      showSale: false, // 上游下拉框
      showPurchase: false, // 采购方下拉框
      showSku: false, // sku下拉框
      showReAppealBtn: false, // 是否展示重新申诉按钮
      detail: {}, // 流向详情
      appealDetail: {}, // 流向申诉详情
      appealDate: {}, // 申诉期数据
      flowLackAppeal: {
        ym: null,
        isMonthly: true,
        businessDate: null,
        salerId: null,
        purchaseId: null,
        qty: null,
        remark: null,
      }, // 整月/非整月提交信息
      limitYm: '', // 限制ym
      limitDate: '', // 限制实际日期
      productIdList: [],
      productIdCheckList: [],
      appealQty: null, // 申诉数量
      reason: null, // 申诉理由
      transidDetail: {}, // 生效流向详情
      appealType: 'appeal',
      saleList: [], // 上游下拉框
      purchaseList: [], // 采购方下拉框
      skuList: [], // sku树形数据
      saleValue: '', // 上游搜索
      purchaseValue: '', // 采购方搜索
      saleName: null, // 上游名称
      purchaseName: null, // 采购方名称
      skuName: [], // sku名称
      skuCheckName: [], // sku名称
      props: {
        label: 'brandName',
        children: 'productList',
      },
    }
  },
  filters: {
    appealState(val) {
      let str = ''
      if (val == 0) {
        str = '已提交'
      } else if (val == 1) {
        str = '核查中'
      } else if (val == 2) {
        str = '已确认'
      } else if (val == 3) {
        str = '已拒绝'
      } else if (val == 4) {
        str = '已生效'
      }
      return str
    },
  },
  computed: {
    stateColor() {
      return val => {
        let str = ''
        if (val == 0) {
          str = 'state-submit'
        } else if (val == 1) {
          str = 'state-check'
        } else if (val == 2) {
          str = 'state-confirm'
        } else if (val == 3) {
          str = 'state-refuse'
        } else if (val == 4) {
          str = 'state-effect'
        }
        return str
      }
    },
    maxYm() {
      return moment(this.limitYm).toDate()
    },
    minDate() {
      return moment(this.limitDate).startOf('month').toDate()
    },
    maxDate() {
      return moment(this.limitDate).endOf('month').toDate()
    },
    // 校验是否有查看权限
    hasAuth() {
      return auth => {
        let permissionList = []
        let authList = JSON.parse(sessionStorage.getItem('LOGIN_USER_BUTTON_AUTH') || '[]')
        for (let auth of authList) {
          if (auth.type != '2') {
            permissionList.push(auth)
          }
        }
        let permissions = []
        for (let item of permissionList) {
          if (item.type != '2') {
            //update--begin--autor:wangshuai-----date:20200729------for：按钮权限，授权标识的提示信息是多个用逗号分隔逻辑处理 gitee#I1OUGU-------
            if (item.action) {
              if (item.action.includes(',')) {
                let split = item.action.split(',')
                for (let i = 0; i < split.length; i++) {
                  if (!split[i] || split[i].length == 0) {
                    continue
                  }
                  permissions.push(split[i])
                }
              } else {
                permissions.push(item.action)
              }
            }
            //update--end--autor:wangshuai-----date:20200729------for：按钮权限，授权标识的提示信息是多个用逗号分隔逻辑处理 gitee#I1OUGU------
          }
        }
        if (!permissions.includes(auth)) {
          return false
        } else {
          return true
        }
      }
    },
  },
  mounted() {},
  watch: {
    showDetail(val) {
      if (!val) {
        this.showTransidDetail = false
        this.showTransids = false
      }
      console.log(val)
    },
    showAppeal(val) {
      if (!val) {
        this.cancelSubmit()
      }
    },
    'flowLackAppeal.ym': {
      handler: function (val) {
        this.flowLackAppeal.businessDate = null
        this.limitDate = moment(val).format('YYYYMM')
        this.getSkuList()
      },
      deep: true, //深度侦听
    },
  },
  methods: {
    changeTab(name) {
      if (name == 0) {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'tab',
          $url_path: '/amountAppeals',
          $element_content: '申诉状态',
        })
        this.$refs.appealStatus.search()
      } else {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'tab',
          $url_path: '/amountAppeals',
          $element_content: '流向查看',
        })
        this.$refs.flowView.search()
      }
    },
    // 获取申诉期控制是否可发起申诉
    getAppealDate(val) {
      this.isAppeal = val.isWithinAppeal
      this.appealDate = val
    },
    // 校验申诉数量
    validator(val) {
      if (!val) {
        this.inputErr = true
        // this.$toast('请输入申诉数量')
      } else if (val == this.appealDetail.oriQty) {
        this.inputErr = true
        this.$toast('申诉数量不可与原始数量一致')
      } else {
        this.inputErr = false
      }
    },

    // 获取整月/非整月流向最大月份
    getMonthlySalesYm() {
      getAction('/sip/appeal_time/getMaxFinalMonthlySalesYm').then(res => {
        console.log('获取整月/非整月流向最大月份------', res)
        // this.flowLackAppeal.ym = String(res.result)
        this.flowLackAppeal.ym = moment().subtract(1, 'months').format('YYYYMM')
        this.limitYm = moment().subtract(1, 'months').format('YYYYMM')
        this.limitDate = moment().subtract(1, 'months').format('YYYYMM')
        this.getSaleList()
        this.getPurchaserList()
        this.getSkuList()
      })
    },
    // 选择实际年月
    confirmYm(val) {
      this.flowLackAppeal.ym = moment(val).format('YYYYMM')
      this.showYm = false
    },
    // 选择实际日期
    confirmDate(val) {
      this.flowLackAppeal.businessDate = moment(val).format('YYYY-MM-DD')
      this.showDate = false
    },
    // 搜索上游
    searchSale: _.debounce(function (val) {
      this.getSaleList(val)
    }, 500),
    // 搜索采购方
    searchPurchase: _.debounce(function (val) {
      this.getPurchaserList(val)
    }, 500),
    // 获取上游
    getSaleList(name) {
      getAction('/sip/flow_lack/saler', { ym: this.flowLackAppeal.ym, name }).then(res => {
        console.log('获取上游下拉框------', res)
        this.saleList = res.result
      })
    },
    // 获取采购方
    getPurchaserList(name) {
      getAction('/sip/flow_lack/purchaser', { ym: this.flowLackAppeal.ym, name }).then(res => {
        console.log('获取采购方下拉框------', res)
        this.purchaseList = res.result
      })
    },
    // 获取SKU
    getSkuList() {
      getAction('/sip/flow_lack/form_init_mobile', { ym: this.flowLackAppeal.ym }).then(res => {
        console.log('获取SKU下拉框------', res)
        this.skuList = res.result
      })
    },
    // 选择上游
    confirmSale(val) {
      console.log('选择上游------', val)
      this.flowLackAppeal.salerId = val.id
      this.saleName = val.name
      this.showSale = false
    },
    // 选择采购方
    confirmPurchase(val) {
      console.log('选择采购方------', val)
      this.flowLackAppeal.purchaseId = val.id
      this.purchaseName = val.name
      this.showPurchase = false
    },
    // sku取消
    cancelSku() {
      this.showSku = false
    },
    // sku确认
    confirmSku() {
      this.productIdList = JSON.parse(JSON.stringify(this.productIdCheckList))
      this.skuName = JSON.parse(JSON.stringify(this.skuCheckName))
      this.showSku = false
    },
    // 选择sku
    checkSku(data) {
      // this.productIdList = data
      if (!this.flowLackAppeal.isMonthly) {
        this.productIdCheckList = this.productIdCheckList.includes(data.productId) ? [data.productId] : []
        this.skuCheckName = this.skuCheckName.includes(data.productName) ? [] : [data.productName]
      } else {
        if (this.skuCheckName.indexOf(data.productName) == -1) {
          this.skuCheckName.push(data.productName)
        } else {
          this.skuCheckName.splice(this.skuCheckName.indexOf(data.productName), 1)
        }
      }
    },
    rotateButton() {
      if (this.isAppeal) {
        this.isClicked = !this.isClicked
      } else {
        this.$toast('不在申诉期')
      }
    },
    // 整月缺失/非整月缺失
    monthlyAppeal(type, isMonthly) {
      if (type === 'monthly') {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'div',
          $url_path: '/amountAppeals',
          $element_content: '整月缺失',
        })
      } else {
        // 触发埋点
        this.$sensors.track('ButtonClicked', {
          $element_type: 'div',
          $url_path: '/amountAppeals',
          $element_content: '非整月缺失',
        })
      }
      this.appealType = type
      this.flowLackAppeal.isMonthly = isMonthly
      this.getMonthlySalesYm()
      this.showAppeal = true
    },
    viewDetail(id) {
      getAction('/sip/appeal/status/details', { id }).then(res => {
        console.log('查看详情------', res)
        if (res.success) {
          this.detail = res.result
          this.showDetail = true
          if (
            this.appealDate.isWithinAppeal &&
            this.appealDate.startYm <= Number(this.detail.ym) &&
            Number(this.detail.ym) <= this.appealDate.endYm
          ) {
            this.showReAppealBtn = true
          } else {
            this.showReAppealBtn = false
          }
        }
      })
    },
    editAppeal(type, record) {
      console.log('record', record)
      this.appealDetail = record
      this.appealType = type
      this.showAppeal = true
    },
    // 复制
    copyText(text, e) {
      let value = text //这里设置你要复制的文本信息，可以是text，也可以从e中获取，也可以自定义
      handleCopy(
        value,
        e,
        () => {
          this.$toast('复制成功')
        },
        () => {
          this.$toast('复制失败')
        }
      )
    },
    // 点击生效流向跳转详情
    handleTransid(transid) {
      this.detail.resultList.forEach(v => {
        if (transid == v.transid) {
          this.showTransidDetail = true
          this.transidDetail = v
          console.log(v)
        }
      })
    },
    // 重新申诉
    reAppeal() {
      if (this.detail.type === '流向申诉') {
        this.editAppeal('appeal', this.detail)
      } else {
        if (this.detail.isMonthly) {
          this.monthlyAppeal('monthly', true)
        } else {
          this.monthlyAppeal('nonMonthly', false)
        }
      }
    },
    // 提交
    onSubmit(values) {
      let requestApi
      let params
      this.$dialog
        .confirm({
          message: '确认提交申诉吗',
        })
        .then(() => {
          if (this.appealType === 'appeal' && this.appealQty) {
            // 触发埋点
            this.$sensors.track('ButtonClicked', {
              $element_type: 'div',
              $url_path: '/amountAppeals',
              $element_content: '去申诉确认',
            })
            requestApi = '/sip/final_monthly_sales/appeal'
            params = { appealQty: this.appealQty, reason: this.reason, transid: this.appealDetail.transid }
            postAction(requestApi, params).then(res => {
              console.log('res---------', res)
              if (res.code == 200) {
                this.$toast('申诉提交成功')
                this.$refs.flowView.search()
                // this.$refs.appealStatus.search()
                this.showAppeal = false
                this.showDetail = false
              } else {
                this.$toast(res.message)
              }
            })
          } else {
            requestApi = '/sip/flow_lack/insert'
            this.flowLackAppeal.remark = this.reason
            this.flowLackAppeal.qty = this.appealQty
            params = { flowLackAppeal: this.flowLackAppeal, productIdList: this.productIdList }
            // if (!this.flowLackAppeal.isMonthly) {
            //   params = { flowLackAppeal: this.flowLackAppeal, productIdList: this.productIdList }
            // }
            if (!this.flowLackAppeal.isMonthly || this.appealType === 'appeal') {
              if (!this.flowLackAppeal.qty) {
                this.$toast('请输入申诉数量')
                this.inputErr = true
                var id = '#qty'
                document.querySelector(id).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                })
                return
              } else if (this.flowLackAppeal.qty == this.appealDetail.oriQty) {
                this.inputErr = true
                this.$toast('申诉数量不可与原始数量一致')
                var id = '#qty'
                document.querySelector(id).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'nearest',
                })
                return
              } else {
                this.inputErr = false
              }
            }
            this.inputErr = false
            this.ymErr = false
            this.salerErr = false
            this.purchaseErr = false
            this.skuErr = false
            if (!this.flowLackAppeal.ym) {
              this.$toast('请选择实际年月')
              var id = '#ym'
              document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
              this.ymErr = true
              return
            }
            if (!this.flowLackAppeal.salerId) {
              this.$toast('请选择上游')
              var id = '#saler'
              document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
              this.salerErr = true
              return
            }
            if (!this.flowLackAppeal.purchaseId) {
              this.$toast('请选择采购方')
              var id = '#purchase'
              document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
              this.purchaseErr = true
              return
            }
            if (!this.productIdList.length) {
              this.$toast('请选择SKU')
              var id = '#sku'
              document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
              this.skuErr = true
              return
            }
            if (this.flowLackAppeal.isMonthly) {
              // 触发埋点
              this.$sensors.track('ButtonClicked', {
                $element_type: 'div',
                $url_path: '/amountAppeals',
                $element_content: '整月缺失确认',
              })
            } else {
              // 触发埋点
              this.$sensors.track('ButtonClicked', {
                $element_type: 'div',
                $url_path: '/amountAppeals',
                $element_content: '非整月缺失确认',
              })
            }
            postAction(requestApi, params).then(res => {
              console.log('res---------', res)
              if (res.code == 200) {
                this.$toast('申诉提交成功')
                this.$refs.appealStatus.search()
                this.active = 0
                // this.$refs.appealStatus.handleStatus(0, 0)
                this.showAppeal = false
                this.showDetail = false
              } else {
                this.$toast(res.message)
              }
            })
          }
        })
        .catch(() => {})
    },
    // 取消
    cancelSubmit() {
      this.showAppeal = false
      // this.showDetail = false
      this.inputErr = false
      this.ymErr = false
      this.salerErr = false
      this.purchaseErr = false
      this.skuErr = false
      this.flowLackAppeal = {
        ym: null,
        isMonthly: true,
        businessDate: null,
        salerId: null,
        purchaseId: null,
        qty: null,
        remark: null,
      }
      this.appealDetail = {}
      this.productIdList = []
      this.productIdCheckList = []
      this.appealQty = null
      this.reason = null
      this.saleList = []
      this.purchaseList = []
      this.skuList = []
      this.saleValue = ''
      this.purchaseValue = ''
      this.saleName = null
      this.purchaseName = null
      this.skuName = []
      this.skuCheckName = []
    },
  },
}
</script>

<style lang="less" scoped>
.ml3 {
  margin-left: 3px;
}
.ml6 {
  margin-left: 6px;
}
.sku-toobar {
  width: 100%;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  div {
    width: 54px;
    font-size: 14px;
    color: #576b95;
    text-align: center;
  }
  div:first-child {
    color: #969799;
  }
}
/deep/ .el-tree {
  margin-top: 55px;
}
.main {
  padding-top: 45px;
}
/deep/ .el-checkbox__label {
  font-size: 12px;
}
/deep/ .van-tabs__wrap {
  width: 100%;
  height: 45px !important;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f6f7fb;
  z-index: 999;
}

/deep/ .van-tabs__nav {
  background: none;
  .van-tab__text {
    font-size: 15px;
    font-weight: 600;
  }
  .van-tab--active {
    color: #4e7092;
  }
}
/deep/ .van-tabs__line {
  background-color: #02a7f0;
  width: 20%;
}
.tips {
  padding: 10px;
}
.remark-tips {
  width: 200px;
  padding: 10px;
  word-wrap: break-word;
}
// 流向申诉悬浮按钮
.appeal {
  width: 45px;
  height: 45px;
  font-size: 12px;
  color: #fff;
  padding: 10px;
  background-image: url(/appeal_btn.png);
  background-size: cover;
  position: fixed;
  right: 15px;
  bottom: 100px;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .missing {
    width: 115px;
    height: 75px;
    color: #02a7f0;
    background: #fff;
    position: absolute;
    left: -100px;
    top: -60px;
    border-radius: 7px;
    box-shadow: 0 0 5px #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px 10px;
    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div:nth-child(2) {
      width: 100%;
      height: 1px;
      background: #f2f2f2;
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: transform 0.5s, opacity 0.5s;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translate(10%, 10%);
    opacity: 0;
  }

  img {
    width: 20px;
    height: 20px;
    /* 初始状态无旋转 */
    transform: rotate(0deg);
    transition: transform 0.5s ease; /* 添加过渡效果 */
  }
  /* 点击时添加旋转效果 */
  .clicked {
    transform: rotate(45deg);
  }
}
// 重新申诉按钮
.re-appeal {
  width: 100%;
  height: 43px;
  font-size: 13px;
  border-radius: 16px;
  background-color: #6fc3ff;
  color: #fff;
  border: none;
  line-height: 43px;
  margin-bottom: 10px;
}
// 详情
.detail-popup-title {
  width: 100%;
  height: 50px;
  color: #555;
  background: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 17px;
  z-index: 9999;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 14px;
    height: 14px;
  }
}
.detail-popup-box {
  margin-top: 65px;
  padding: 0 15px;
  color: #4e7092;
}
.detail-title {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .state {
    width: 60px;
    height: 24px;
    font-size: 10px;
    font-weight: normal;
    color: #02a7f0;
    background: rgba(92, 192, 235, 20%);
    border-radius: 7px;
    text-align: center;
    line-height: 24px;
  }
  .state-submit {
    color: #ffc700;
    background: rgba(255, 255, 0, 0.2);
  }
  // 核查中
  .state-check {
    color: #f59a23;
    background: #fff1cc;
  }
  // 已确认
  .state-confirm {
    color: #69ce96;
    background: #e5fbed;
  }
  // 已拒绝
  .state-refuse {
    color: #ed8788;
    background: #ffefec;
  }
  // 已生效
  .state-effect {
    color: #02a7f0;
    background: rgba(92, 192, 235, 0.2);
  }
}
.detail-content {
  width: 100%;
  // min-height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 7px;
  padding: 3px 10px;
  // padding-top: 2px;
  margin-bottom: 10px;
  position: relative;
  /deep/ .van-cell {
    font-size: 12px;
    color: #4e7092;
    padding-left: 0;
    padding-right: 0;
    padding: 3px 0;
  }
  .van-cell__title {
    font-weight: 600;
  }
  .van-cell__value {
    color: #4e7092;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    div {
      // display: -webkit-box;
      // overflow: hidden;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .van-popover__wrapper {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .flow-code {
    cursor: pointer;
    span {
      border-bottom: 1px solid #4e7092;
    }
  }
  .appeal-num {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    img {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }
  }
  // 生效流向
  .detail-transids {
    width: 125px;
    min-height: 65px;
    font-size: 13px;
    color: #02a7f0;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 0 8px 1px #f2f2f2;
    position: absolute;
    bottom: 35px;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;

    .transid {
      border-bottom: 1px solid #02a7f0;
      margin-bottom: 5px;
    }
  }
}
.input,
.textarea {
  width: 100%;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 8px;
}
.input {
  height: 27px;
  padding: 0 12px;
}
/deep/ .van-field__control--right {
  padding-right: 12px;
}
.input-err {
  width: 100%;
  height: 27px;
  padding: 0 12px;
  font-size: 12px;
  border: 1px solid #ee0a24;
  border-radius: 8px;
}
.font-err {
  color: #ee0a24;
}

.appeal-title {
  flex: 0.5;
}
.form-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 12px;
  .btn {
    width: 140px;
    height: 43px;
    font-size: 13px;
    color: #4e7092;
    border-radius: 16px;
    background: #f2f2f2;
    line-height: 43px;
  }
  .van-button--info {
    background-color: #6fc3ff;
    color: #fff;
    border: none;
  }
}
</style>
