import Vue from 'vue'
import axios from 'axios'
// import { VueAxios } from './axios'
import { ACCESS_TOKEN, TENANT_ID } from '@/store/mutation-types'
import { Toast } from 'vant'
import router from '@/router'
import Wartermark from '@/utils/wartermark'

axios.defaults.withCredentials = true

let apiBaseUrl = '/jeecg-boot'
// 创建 axios 实例
const service = axios.create({
  // baseURL: '/api', // api base_url
  baseURL: apiBaseUrl, // api base_url
  timeout: 500000, // 请求超时时间
})

// request interceptor
service.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem(ACCESS_TOKEN) || Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
      // if (Vue.ls.get('ntid')) {
      //   Wartermark.set(Vue.ls.get('ntid'))
      // }
    }
    let menuUrl = window.location.href
    if (menuUrl.includes('preValidationSite')) {
      config.headers['preCheck'] = 'preCheck'
    }
    //update-begin-author:taoyan date:2020707 for:多租户
    let tenantid = Vue.ls.get(TENANT_ID)
    if (!tenantid) {
      tenantid = 0
    }
    config.headers['tenant_id'] = tenantid
    config.headers['X-Frame-Options'] = 'SAME ORGIN'
    //update-end-author:taoyan date:2020707 for:多租户
    if (config.method == 'get') {
      if (config.url.indexOf('sys/dict/getDictItems') < 0) {
        config.params = {
          _t: Date.parse(new Date()) / 1000,
          ...config.params,
        }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const err = error => {
  console.log('error----------', error)
  if (error.response) {
    if (error.response.status == 401) {
      Toast.fail('未授权，请重新登录')
      router.push('/login')
    } else if (error.response.data.message === 'Token失效，请重新登录') {
      Toast.fail(error.response.data.message)
      router.push('/login')
    }
  }
  return Promise.reject(error)
}

// response interceptor
service.interceptors.response.use(response => {
  if (Object.prototype.toString.call(response.data).indexOf('Blob') > -1) {
    return response
  }
  return response.data
  // const { request, status, data } = response
  // if (status !== 200) {
  //   notification.error({
  //     message: '错误',
  //     description: '服务器异常',
  //   })
  //   return Promise.reject(data)
  // }
  // if (request.responseType !== 'blob' && data.code == 401) {
  //   store.push('login')
  //   return Promise.reject(data)
  // } else if (request.responseType !== 'blob' && data.code == -1) {
  //   notification.error({
  //     message: '错误',
  //     description: '服务器异常',
  //   })
  //   return Promise.reject(data)
  // } else if (request.responseType !== 'blob' && data.code != 0) {
  //   message.error(data.msg)
  //   return Promise.reject(data)
  // } else {
  //   return Promise.resolve(data)
  // }
}, err)

export { service as axios }
