import Vue from 'vue'
import moment from 'moment'

Vue.filter('otherFilter', function (value) {
  if (value === 'other') {
    value = '其他'
  }
  return value
})

Vue.filter('NumberFormat', function (value, isInt = false) {
  // isInt => 是否只保留整数部分
  if (!value) {
    return '0'
  }
  // let intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
  if (isInt) {
    value = Math.round(value)
  } else {
    value = Math.floor(value * 100) / 100
  }
  let intPartFormat = value
    .toString()
    .replace(new RegExp(`(?!^)(?=(\\d{3})+${value.toString().includes('.') ? '\\.' : '$'})`, 'g'), ',') //将整数部分逢三一断
  return intPartFormat
})

Vue.filter('date', function (value, format = 'YYYY/MM/DD') {
  if (value) {
    return moment(value).format(format)
  }
})
