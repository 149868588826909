import { render, staticRenderFns } from "./AppealStatus.vue?vue&type=template&id=0f2c282b&scoped=true"
import script from "./AppealStatus.vue?vue&type=script&lang=js"
export * from "./AppealStatus.vue?vue&type=script&lang=js"
import style0 from "./AppealStatus.vue?vue&type=style&index=0&id=0f2c282b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2c282b",
  null
  
)

export default component.exports