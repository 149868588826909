import { render, staticRenderFns } from "./SkuTable.vue?vue&type=template&id=fa4f61ea&scoped=true"
import script from "./SkuTable.vue?vue&type=script&lang=js"
export * from "./SkuTable.vue?vue&type=script&lang=js"
import style0 from "./SkuTable.vue?vue&type=style&index=0&id=fa4f61ea&prod&lang=less&scoped=true"
import style1 from "./SkuTable.vue?vue&type=style&index=1&id=fa4f61ea&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa4f61ea",
  null
  
)

export default component.exports