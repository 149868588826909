import Vue from 'vue'
import { axios } from '@/utils/request'

//post
export function postAction(url, parameter, params) {
  return axios({
    url: url,
    method: 'post',
    data: parameter,
    ...params,
  })
}

//get
export function getAction(url, parameter, param = {}) {
  return axios({
    url: url,
    method: 'get',
    ...param,
    params: parameter,
  })
}

//put
export function putAction(url, parameter) {
  return axios({
    url: url,
    method: 'put',
    data: parameter,
  })
}

//post
export async function syncPostAction(url, parameter, params) {
  return await axios({
    url: url,
    method: 'post',
    data: parameter,
    ...params,
  })
}

//deleteAction
export function deleteAction(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    params: parameter,
  })
}

export function logout(logoutToken) {
  return axios({
    url: '/sys/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Token': logoutToken,
    },
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter, method = 'get') {
  const param = {
    url: url,
    method,
    responseType: 'blob',
  }
  if (method === 'get') {
    param.params = parameter
  } else {
    param.data = parameter
  }
  return axios(param)
}
