<template>
  <div>
    <el-table
      :data="tableData"
      stripe
      height="280"
      :cell-style="{ padding: '3px' }"
      :header-cell-style="{ padding: '3px' }"
      style="width: 100%; font-size: 12px"
    >
      <el-table-column prop="businessDate" label="业务日期" width="100" align="center"></el-table-column>
      <el-table-column label="上游" width="240" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            :visible-arrow="false"
            :content="scope.row.saleName"
            popper-class="tooltip-class"
            placement="top-start"
          >
            <div class="ellipsis">{{ scope.row.saleName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="采购方" width="240" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            :visible-arrow="false"
            :content="scope.row.purchaseName"
            popper-class="tooltip-class"
            placement="top-start"
          >
            <div class="ellipsis">{{ scope.row.purchaseName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="SKU" width="140" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            :visible-arrow="false"
            :content="scope.row.skuName"
            popper-class="tooltip-class"
            placement="top-start"
          >
            <div class="ellipsis">{{ scope.row.skuName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="实际数量(标准)" width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.actualQuantity | NumberFormat(true) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际金额" width="120" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.actualAmount | NumberFormat(true) }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      small
      layout="prev, pager, next, total"
      :total="total"
      :page-size="ipagination.pageSize"
      @current-change="handleTableChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => {},
    },
    ipagination: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  methods: {
    handleTableChange(pagination) {
      this.$emit('change', pagination)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-pagination {
  width: 100%;
  overflow-x: auto;
  li {
    margin: 0 3px !important;
  }
  .btn-prev {
    margin: 0 3px !important;
  }
  .btn-next {
    margin: 0 3px !important;
  }
  .btn-quickprev {
    margin: 0px !important;
  }
  .btn-quicknext {
    margin: 0px !important;
  }
}
.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>

<style lang="less">
.tooltip-class {
  font-size: 12px;
  color: #606266;
  border: 1px solid #fff !important;
  box-shadow: 0 0 5px #ccc;
}
</style>
