import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import './style/base.less'
import hasPermission from '@/utils/hasPermission'
Vue.use(hasPermission)

// 引入lib-flexible
import 'lib-flexible'

import Storage from 'vue-ls'
Vue.use(Storage)

import { Table, TableColumn, Pagination, Tree, CheckboxGroup, Checkbox, Tooltip } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Tree)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Tooltip)

import sensors from 'sa-sdk-javascript'
if (window.performance.navigation.type == 1) {
  if (localStorage.getItem('Access-Token')) {
    let token = JSON.parse(localStorage.getItem('Access-Token')).value
    sensors.init({
      server_url: process.env.VUE_APP_SENSORS_API_BASE_URL + `/sys/sensors/report?token=${token}&type=mobile`,
      is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
      use_client_time: true,
      send_type: 'beacon',
      heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect',
      },
    })

    sensors.quick('autoTrack') //用于采集 $pageview 事件。
    Vue.prototype.$sensors = sensors
  }
}

// 引入vant
import Vant from 'vant'
import { Toast, Dialog, ImagePreview } from 'vant'
import 'vant/lib/index.css'
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$imagePreview = ImagePreview
Vue.use(Vant)

import '@/utils/filter' // base filter
import '@/utils/directives.js'

// 注册公共组件
import AbbvieHeader from './components/AbbvieHeader.vue'
import AbbvieFooter from './components/AbbvieFooter.vue'
Vue.component('abbvie-header', AbbvieHeader)
Vue.component('abbvie-footer', AbbvieFooter)
// 分页组件
import AbbviePagination from './components/Pagination/index.vue'
Vue.component('Pagination', AbbviePagination)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
