<template>
  <div class="main">
    <!-- <img class="login-bg" src="/login_bg.png" /> -->
    <!-- <van-form @submit="login">
      <div class="form">
        <div class="input-title">账号：</div>
        <div class="input">
          <van-field
            v-model="username"
            name="username"
            type="username"
            :rules="[{ required: true, message: '请填写账号' }]"
          />
        </div>
        <div class="input-title">密码：</div>
        <div class="input">
          <van-field
            v-model="password"
            name="password"
            :type="passwordType"
            :rules="[{ required: true, message: '请填写密码' }]"
          >
            <template slot="right-icon">
              <span class="solts" @click="switchPasswordType">
                <van-icon name="eye" v-if="passwordType === 'password'" />
                <van-icon name="closed-eye" v-else />
              </span>
            </template>
          </van-field>
        </div>
        <van-checkbox checked-color="#32B8C7" icon-size="20px" v-model="rememberPassword">
          <div style="color: #93a2ad; font-size: 18px">记住密码</div>
        </van-checkbox>
        <van-button class="btn" native-type="submit">登录</van-button>
      </div>
    </van-form> -->
    <div class="loading" v-if="!showTips">
      <van-loading type="spinner" color="#515151" size="24px" />
      <div class="mt10">加载中</div>
    </div>
    <div class="tips" v-else>
      <div>本应用仅适用于销售团队</div>
      <div>销售团队中新入职员工请联系助理</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Wartermark from '@/utils/wartermark'
import sensors from 'sa-sdk-javascript'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getAction, postAction } from '@/api/manage'
export default {
  data() {
    return {
      username: '',
      password: '',
      passwordType: 'password',
      rememberPassword: false,
      code: '',
      showTips: false, // 是否显示登录错误提示
    }
  },
  created() {
    if (Vue.ls.get('rememberPassword')) {
      this.getRememberPassword()
    }
    this.isSsoLogin()
  },
  methods: {
    // 是否显示密码
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    // 获取记住密码
    getRememberPassword() {
      this.username = Vue.ls.get('username')
      this.password = Vue.ls.get('password')
      this.rememberPassword = Vue.ls.get('rememberPassword')
    },
    login(values) {
      postAction('/sys/login', values).then(res => {
        if (res.code == 200) {
          if (this.rememberPassword) {
            Vue.ls.set('username', this.username)
            Vue.ls.set('password', this.password)
          } else {
            Vue.ls.remove('username')
            Vue.ls.remove('password')
          }
          Vue.ls.set('rememberPassword', this.rememberPassword)
          Vue.ls.set(ACCESS_TOKEN, res.result.token)
          Vue.ls.set('territoryType', res.result.userInfo.territoryType)
          // this.getUserInfo()
          this.$toast.success('登陆成功')
          this.$router.push('/dataBoard')
        } else {
          this.$toast(res.message)
        }
      })
    },
    getUserInfo() {
      getAction('/sys/order/my/information').then(res => {
        console.log('我的信息--------', res)
        if (res.success) {
          Vue.ls.set('ntid', res.result.ntid)
          Wartermark.set(Vue.ls.get('ntid'))
        }
      })
    },
    // 登录权限控制
    getPermission() {
      getAction('/sys/permission/getUserPermissionByTokenMobile').then(res => {
        console.log('登录权限控制------', res)
        if (res.result.isMobileAccessPermissions) {
          const authData = res.result.auth
          sessionStorage.setItem('LOGIN_USER_BUTTON_AUTH', JSON.stringify(authData))
          this.$toast.success('登陆成功')
          this.$router.push('/dataBoard')
        } else {
          this.showTips = true
        }
      })
    },
    // 判断是否调用sso登录
    isSsoLogin() {
      getAction('/sip/serverConfig/queryByKey', { key: 'mobile_sso_login' }).then(res => {
        console.log('判断是否是sso登录------', res.result[0].jsonbConfig.is_mobile_sso_login)
        let isMobileSsoLogin = res.result[0].jsonbConfig.is_mobile_sso_login
        if (isMobileSsoLogin) {
          this.getInfo()
        } else {
          this.$router.push('/login')
        }
      })
    },
    // 获取sso登录state
    getInfo() {
      this.code = this.$route.query.code
      getAction('/sys/auth/info').then(res => {
        if (res.result.state) {
          this.ssoLogin({ code: this.code, state: res.result.state })
        }
      })
    },
    // 获取sso登录
    ssoLogin(params) {
      getAction('/sys/auth/callback', params).then(res => {
        console.log('获取token-------', res)
        if (res.code == 200) {
          Vue.ls.set(ACCESS_TOKEN, res.result.token)
          Vue.ls.set('territoryType', res.result.territoryType)
          // Vue.ls.set('ntid', res.result.userInfo.username)
          // Wartermark.set(Vue.ls.get('ntid'))
          sensors.init({
            server_url:
              process.env.VUE_APP_SENSORS_API_BASE_URL + `/sys/sensors/report?token=${res.result.token}&type=mobile`,
            is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
            use_client_time: true,
            send_type: 'beacon',
            heatmap: {
              //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
              clickmap: 'default',
              //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
              scroll_notice_map: 'not_collect',
            },
          })
          sensors.quick('autoTrack') //用于采集 $pageview 事件。
          Vue.prototype.$sensors = sensors

          this.getPermission()
          // this.$toast.success('登陆成功')
          // this.$router.push('/dataBoard')
        } else {
          this.$toast(res.message)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.mt10 {
  margin-top: 10px;
}
.main {
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #333;
  }
  .tips {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0 20px;
  }
}
.login-bg {
  width: 100%;
}
.form {
  padding: 0 24px;
  margin-top: 10px;
  .input-title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
  }
  .input {
    margin: 9px 0;
    display: flex;
    align-items: center;
    border: 1px solid #32b8c7;
    border-radius: 6px;
    /deep/ .van-field {
      background-color: transparent;
      &::after {
        border: none;
      }
      &__control {
        font-size: 18px;
      }
    }
    > span {
      flex-shrink: 0;
      font-weight: 600;
      margin-right: 16px;
    }
  }
  .btn {
    z-index: 999;
    width: 100%;
    height: 42px;
    background: #32b8c7;
    border-radius: 10px;
    margin-top: 30px;
    color: #fff;
    font-weight: 500;
    border: none;
  }
}
</style>
