<template>
  <div>
    <div class="fixed">
      <!-- 筛选框 -->
      <div class="filter-date">
        <div class="filter-left">
          <div class="date" @click="showDate = true">
            {{ queryParam.ym | date('MM月 YYYY') }} <img src="/arrow_down.png" />
          </div>
          <div class="appeal">
            <span v-if="appealDate.isWithinAppeal">申诉截止至：</span>{{ appealDate.appealDeadlineDetail }}
          </div>
        </div>
        <div :class="{ filter: true, 'filter-non': !isFilter, 'filter-active': isFilter }" @click="showFilter = true">
          <img src="/filter.png" v-if="!isFilter" />
          <img src="/filter_active.png" v-else />
        </div>
      </div>
    </div>
    <!-- 状态 -->
    <div class="status">
      <div
        class="status-btn"
        :class="{ isActive: curIndex == index }"
        v-for="(item, index) in statusList"
        :key="index"
        @click="handleStatus(item.state, index)"
      >
        {{ item.value }}
      </div>
    </div>
    <!-- 列表 -->
    <div class="list">
      <!-- 卡片信息 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="appealList.length == 0 ? '暂无数据' : '没有更多了'"
          @load="onLoad"
        >
          <div class="info" v-for="(item, index) in appealList" :key="index">
            <!-- 标题 -->
            <div class="info-title">
              <div class="content">
                <div :class="[item.type === '流向申诉' ? 'logo-appeal' : 'logo-direction', 'logo']"></div>
                <div class="text">{{ item.productName }}</div>
              </div>
              <div :class="[stateColor(item.state), 'tag']">
                {{ item.state | appealState }}
              </div>
            </div>
            <!-- 信息类型 -->
            <div class="info-type">
              <div class="type">{{ item.type }}</div>
              <div class="num" v-if="item.type === '流向申诉'">{{ item.oriQty }}{{ item.unit }}</div>
            </div>
            <!-- 内容信息 -->
            <div class="info-content">
              <div class="sale">
                <van-row>
                  <van-col span="6" class="sale-name">上游</van-col>
                  <van-col span="18">
                    <van-popover v-model="item.salerPopover" placement="top" trigger="click">
                      <div class="tips">
                        <div>{{ item.salerName }}</div>
                      </div>
                      <template #reference>
                        <div class="sale-content">{{ item.salerName }}</div>
                      </template>
                    </van-popover>
                  </van-col>
                </van-row>
              </div>
              <div class="purchase">
                <van-row>
                  <van-col span="6" class="purchase-name">采购方</van-col>
                  <van-col span="18">
                    <van-popover v-model="item.purchasePopover" placement="top" trigger="click">
                      <div class="tips">
                        <div>{{ item.purchaseName }}</div>
                      </div>
                      <template #reference>
                        <div class="purchase-content">{{ item.purchaseName }}</div>
                      </template>
                    </van-popover>
                  </van-col>
                </van-row>
              </div>
            </div>
            <!-- 查看详情 -->
            <div class="info-bottom" @click="viewDetail(item.id)">
              <div class="date">{{ item.actualDate }}</div>
              <div class="detail" data-sensors-click>查看详情 ></div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <van-popup v-model="showFilter" round position="bottom" get-container="#app" :style="{ height: '80%' }">
      <div class="filter-title">筛选<img src="/filter_close.png" @click="showFilter = false" /></div>
      <div class="filter-box">
        <!-- 筛选框 -->
        <van-form>
          <van-row>
            <van-col span="24" class="input-title">上游</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="salerName"
                placeholder="请选择上游"
                @click="showSale = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="salerName" @click.stop="clearSelect('salerName')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">采购方</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="purchaseName"
                placeholder="请选择采购方"
                @click="showPurchase = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="purchaseName" @click.stop="clearSelect('purchaseName')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
            <van-col span="24" class="input-title">申诉类型</van-col>
            <van-col span="24">
              <van-field
                class="filter-input"
                readonly
                clickable
                v-model="typeShowName"
                placeholder="请选择申诉类型"
                @click="showType = true"
              >
                <template #right-icon>
                  <span class="solts input-icon">
                    <van-icon name="clear" v-if="type" @click.stop="clearSelect('type')" />
                    <van-icon name="arrow-down" v-else />
                  </span>
                </template>
              </van-field>
            </van-col>
          </van-row>

          <div class="filter-btn">
            <van-button class="btn" round block @click="reset">重置</van-button>
            <van-button class="btn" round block type="info" @click="search">应用</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
    <!-- 日期选择框 -->
    <van-popup v-model="showDate" round position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate"
        :max-date="maxYm"
        type="year-month"
        title="选择年月"
        @confirm="confirmDate"
        @cancel="cancelDate"
      />
    </van-popup>
    <!-- 上游 -->
    <van-popup v-model="showSale" position="bottom">
      <van-search v-model="saleValue" @input="searchSale" placeholder="搜索上游" />
      <van-picker show-toolbar :columns="saleList" @confirm="confirmSale" @cancel="showSale = false" />
    </van-popup>
    <!-- 采购方 -->
    <van-popup v-model="showPurchase" position="bottom">
      <van-search v-model="purchaseValue" @input="searchPurchase" placeholder="搜索采购方" />
      <van-picker show-toolbar :columns="purchaseList" @confirm="confirmPurchase" @cancel="showPurchase = false" />
    </van-popup>
    <!-- 申诉类型 -->
    <van-popup v-model="showType" position="bottom">
      <van-picker
        show-toolbar
        :columns="typeList"
        value-key="label"
        @confirm="confirmType"
        @cancel="showType = false"
      />
    </van-popup>
  </div>
</template>

<script>
// import Vue from 'vue'
import { getAction, postAction } from '@/api/manage'
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      showDate: false, // 日期选择
      showFilter: false, // 显示筛选框
      showSale: false, // 显示上游
      showPurchase: false, // 显示采购方
      showType: false, // 显示申诉类型
      isFilter: false, // 是否进行过搜索
      currentDate: new Date(),
      appealDeadDate: '', // 申诉期截至时间
      pageNo: 1, // 分页
      queryParam: {
        ym: moment().format('YYYYMM'),
        state: null,
      },
      salerName: null, // 上游
      purchaseName: null, // 采购方
      saleValue: '', // 上游搜索内容
      purchaseValue: '', // 采购方搜索内容
      type: null, // 申诉类型
      typeShowName: null, // 申诉类型显示名称
      curIndex: 0, // 当前下标
      statusList: [
        { state: null, value: '全部' },
        { state: 0, value: '已提交' },
        { state: 1, value: '核查中' },
        { state: 2, value: '已确认' },
        { state: 3, value: '已拒绝' },
        { state: 4, value: '已生效' },
      ],
      appealDate: {}, // 申诉期时间
      appealList: [], // 申诉列表
      saleList: [], // 上游列表
      purchaseList: [], // 采购方列表
      typeList: [
        { label: '流向申诉', value: 'WrongAmountAppeal' },
        { label: '流向缺失', value: 'LackAppeal' },
      ], // 申诉类型列表
    }
  },
  filters: {
    appealState(val) {
      let str = ''
      if (val == 0) {
        str = '已提交'
      } else if (val == 1) {
        str = '核查中'
      } else if (val == 2) {
        str = '已确认'
      } else if (val == 3) {
        str = '已拒绝'
      } else if (val == 4) {
        str = '已生效'
      }
      return str
    },
  },
  computed: {
    maxYm() {
      return moment().toDate()
    },
    stateColor() {
      return val => {
        let str = ''
        if (val == 0) {
          str = 'tag-submit'
        } else if (val == 1) {
          str = 'tag-check'
        } else if (val == 2) {
          str = 'tag-confirm'
        } else if (val == 3) {
          str = 'tag-refuse'
        } else if (val == 4) {
          str = 'tag-effect'
        }
        return str
      }
    },
  },
  watch: {
    'queryParam.ym': {
      handler: function (val) {
        this.pageNo = 1
        // 清空列表数据
        this.finished = false
        // 重新加载数据
        this.loading = true
        this.appealList = []
        this.getAppealList()
        this.getSeller('seller')
        this.getSeller('purchaser')
      },
      deep: true, //深度侦听
    },
    'queryParam.state': {
      handler: function (val) {
        this.pageNo = 1
        // 清空列表数据
        this.finished = false
        // 重新加载数据
        this.loading = true
        this.appealList = []
        this.getAppealList()
      },
      deep: true, //深度侦听
    },
  },
  mounted() {
    this.getLatestAppeal()
    this.getSeller('seller')
    this.getSeller('purchaser')
    // this.getAppealList()
  },
  methods: {
    // 查看详情
    viewDetail(id) {
      this.$emit('viewDetail', id)
    },
    handleStatus(value, index) {
      this.curIndex = index
      this.queryParam.state = value
    },
    // 选择日期
    confirmDate(val) {
      this.queryParam.ym = moment(val).format('YYYYMM')
      // this.getBoardData()
      // this.getRefresh()
      this.showDate = false
    },
    // 取消日期
    cancelDate() {
      this.showDate = false
    },
    // 选择上游
    confirmSale(val) {
      console.log('选择上游------', val)
      this.salerName = val
      this.showSale = false
    },
    // 选择采购方
    confirmPurchase(val) {
      console.log('选择采购方------', val)
      this.purchaseName = val
      this.showPurchase = false
    },
    // 选择申诉类型
    confirmType(val) {
      console.log('选择申诉类型------', val)
      this.type = val.value
      this.typeShowName = val.label
      this.showType = false
    },
    // 清空上游
    clearSelect(select) {
      if (select === 'salerName') {
        this.salerName = null
      } else if (select === 'purchaseName') {
        this.purchaseName = null
      } else {
        this.type = null
        this.typeShowName = null
      }
    },
    // 搜索上游
    searchSale: _.debounce(function (val) {
      this.getSeller('seller', val)
    }, 500),
    // 搜索采购方
    searchPurchase: _.debounce(function (val) {
      this.getSeller('purchaser', val)
    }, 500),
    // 获取申诉期
    getLatestAppeal() {
      getAction('/sip/appeal_time/getLatestAppealTime').then(res => {
        console.log('获取申诉期------', res)
        this.appealDate = res.result
        this.$emit('getAppealDate', this.appealDate)
      })
    },
    // 获取上游、采购方下拉框
    getSeller(type, name) {
      getAction('/sip/appeal/status/searchSeller', { ym: this.queryParam.ym, type, name }).then(res => {
        if (type === 'seller') {
          console.log('获取上游数据------', res)
          this.saleList = res.result
        } else {
          console.log('获取采购方数据------', res)
          this.purchaseList = res.result
        }
      })
    },
    // 获取申诉列表
    getAppealList() {
      let params = {
        ...this.queryParam,
        salerName: this.salerName,
        purchaseName: this.purchaseName,
        type: this.type,
        pageNo: this.pageNo,
      }
      postAction(`/sip/appeal/status/list?pageNo=${params.pageNo}`, params).then(res => {
        console.log('获取申诉列表------', res)
        this.appealList = [...this.appealList, ...res.result.records]
        this.loading = false
        this.pageNo++
        if (res.result.records.length == 0) {
          this.finished = true
        }
        this.refreshing = false
      })
    },
    // 查询
    search() {
      this.pageNo = 1
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.appealList = []
      this.getAppealList()
      this.showFilter = false
      if (this.salerName || this.purchaseName || this.type) {
        this.isFilter = true
      } else {
        this.isFilter = false
      }
      // Vue.ls.set('queryParam', this.queryParam)
      // Vue.ls.set('filterDate', this.filterDate)
    },
    // 重置
    reset() {
      // this.queryParam = {}
      this.salerName = null
      this.purchaseName = null
      this.type = null
      this.typeShowName = null
      // Vue.ls.remove('queryParam')
      this.isFilter = false
      this.pageNo = 1
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.appealList = []
      this.getAppealList()
    },
    onLoad() {
      this.getAppealList()
      // setTimeout(() => {
      // 加载状态结束
      // this.loading = false
      // 数据全部加载完成
      // this.finished = true
      // }, 1000)
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      this.loading = true
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
.fixed {
  // position: fixed;
  // min-height: 50px;
  // z-index: 999;
  // background-color: #f6f7fb;
  // padding-bottom: 20px;
}

// 筛选框
.filter-date {
  width: 100%;
  min-height: 50px;
  // margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px 10px 10px;
  position: fixed;
  background-color: #f6f7fb;
  z-index: 999;
  .filter-left {
    .date {
      font-size: 20px;
      font-weight: 600;
      color: #555;
      display: flex;
      align-items: center;
      img {
        width: 10px;
        height: 7px;
        margin-left: 10px;
      }
    }
    .appeal {
      font-size: 12px;
      color: #7f7f7f;
      margin-top: 5px;
    }
  }
  .filter {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .filter-non {
    background: #fff;
  }
  .filter-active {
    background: #5cc0eb;
  }
}
// 状态
.status {
  width: 100%;
  // margin-top: 20px;
  padding-top: 100px;
  padding-left: 10px;
  overflow-x: auto;
  display: flex;

  &-btn {
    width: 72px;
    height: 32px;
    font-size: 12px;
    color: rgba(78, 112, 146, 0.5);
    background: #fff;
    border-radius: 36px;
    text-align: center;
    line-height: 32px;
    margin-right: 10px;
    flex-shrink: 0;
  }
}
.isActive {
  color: #fff;
  background: #6fc3ff;
}
// 隐藏滚动条
::-webkit-scrollbar {
  // width: 0 !important;
  display: none;
}
// 列表
.list {
  margin-top: 20px;
  padding: 0 15px;
  // padding-top: 145px;
  // 卡片信息
  .info {
    width: 100%;
    min-height: 152px;
    font-size: 12px;
    color: #4e7092;
    background: #fff;
    border-radius: 8px;
    padding: 10px 3px;
    padding-bottom: 0;
    margin-bottom: 15px;
    // 标题
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 2px;
      padding-right: 20px;
      .content {
        display: flex;
        align-items: center;
        .logo {
          width: 5px;
          height: 17px;
          border-radius: 3px;
          margin-right: 5px;
        }
        // 流向申诉
        .logo-appeal {
          background: #5cc0eb;
        }
        // 流向缺失
        .logo-direction {
          background: #f59a23;
        }
        .text {
          font-size: 15px;
          font-weight: 600;
        }
      }
      .tag {
        font-size: 10px;
        width: 60px;
        height: 24px;
        border-radius: 6px;
        text-align: center;
        line-height: 24px;
      }
      // 已提交
      .tag-submit {
        color: #ffc700;
        background: rgba(255, 255, 0, 0.2);
      }
      // 核查中
      .tag-check {
        color: #f59a23;
        background: #fff1cc;
      }
      // 已确认
      .tag-confirm {
        color: #69ce96;
        background: #e5fbed;
      }
      // 已拒绝
      .tag-refuse {
        color: #ed8788;
        background: #ffefec;
      }
      // 已生效
      .tag-effect {
        color: #02a7f0;
        background: rgba(92, 192, 235, 0.2);
      }
    }
    // 信息类型
    &-type {
      font-weight: 600;
      display: flex;
      align-items: center;
      padding-left: 10px;
      .type {
        color: #4e7092;
        opacity: 0.5;
        margin-right: 15px;
      }
    }
    // 内容信息
    &-content {
      margin-top: 15px;
      padding: 0 25px 12px 17px;
      border-bottom: 1px solid #f2f2f2;

      .sale {
        margin-bottom: 10px;
      }
      .sale-name,
      .purchase-name {
        font-weight: 600;
      }
      .sale-content,
      .purchase-content {
        width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // overflow: hidden;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 1;
      }
    }

    // 查看详情
    &-bottom {
      height: 36px;
      // margin-top: 8px;
      font-weight: 600;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .date {
        opacity: 0.5;
      }
    }
  }
}
.tips {
  padding: 10px;
}
// 筛选框
.filter-box {
  margin-top: 65px;
  padding: 0 25px;
}
.filter-title {
  width: 100%;
  height: 50px;
  color: #555;
  background: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  padding: 17px;
  z-index: 9999;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 1px solid #f2f2f2;
  img {
    width: 14px;
    height: 14px;
  }
}
.input-title {
  font-size: 12px;
  color: #4e7092;
  margin-bottom: 10px;
}
.filter-input {
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: 1px solid #aaa;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-icon {
  display: flex;
  align-items: center;
}
.filter-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  .btn {
    width: 140px;
    height: 43px;
    font-size: 13px;
    color: #4e7092;
    border-radius: 16px;
    background: #f2f2f2;
    line-height: 43px;
  }
  .van-button--info {
    background-color: #6fc3ff;
    color: #fff;
    border: none;
  }
}
</style>
